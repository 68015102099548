import FlowDTO from '@/api/common/FlowDTO'
import getFlows from '@/services/dropdowns/getFlows.service'
import store from '@/store/index'

const executeAction = async (): Promise<FlowDTO[]> => {
  try {
    const response = await getFlows()
    // pasamos los datos a la store de combos
    if (response != null) {
      const data = {
        valores: response,
        tipo: 'FLOWS'
      }
      store.dispatch('combos/updateCombo', { data })
      return response.fields
    }
  } catch (err) {
    console.error('[getFlows] - Ha ocurrido un error al llamar a getFlows')
    throw err
  }
}

export default executeAction
