import UsuarioRequest from '@/services/administracion/models/UsuarioRequest'
import AdministracionRepository from '../AdministracionRepository'
import UsuarioPageable from '@/services/administracion/models/UsuarioPageable'
import UsuarioRequestDTO from '../dto/UsuarioRequestDTO'
import UsuarioResponseDTO from '../dto/UsuarioResponseDTO'
import { dtoToModel, modelRequestToDTO } from '../mapping/AdministacionMapping'
import { xhr } from '@/api/config/Repository'
import PaginationResponseDTO from '@/api/pagination/dto/PaginationResponseDTO'
import { PaginationResponseDTOToPageable } from '@/api/pagination/mapping/MappingPagination'

export default class AdministracionRepositoryImplt implements AdministracionRepository {
  async listAllUsers (request: UsuarioRequest): Promise<UsuarioPageable> {
    const endpoint = 'administration/users'

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    }

    const requestDTO = modelRequestToDTO(request)
    const response = await xhr.post<UsuarioRequestDTO, UsuarioResponseDTO>(endpoint, requestDTO, { headers })
    const data = response.data?.content.map(item => {
      return dtoToModel(item)
    })
    const pagination: PaginationResponseDTO = { ...response.data }

    const result = {
      content: data,
      pageable: PaginationResponseDTOToPageable(pagination)
    }
    return result
  }
}
