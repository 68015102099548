import API from '@/api'
import DownloadActResponse from './models/DownloadActResponse'
import DownloadActRequest from './models/DownloadActRequest'

const executeAction = async (request: DownloadActRequest): Promise<DownloadActResponse> => {
  try {
    const response = await API.TransactionsRepository.downloadAct(request)
    return new DownloadActResponse(response)
  } catch (err) {
    console.error('[downloadAct] - Ha ocurrido un error al obtener downloadAct')
    throw err
  }
}

export default executeAction
