import Xhr from '@/api/config/Xhr'
import { AxiosRequestConfig } from 'axios'
import UserContext from '../modules/UserContext'
import Interceptor from './Interceptor'
import env from '@/services/environment'

export default class InterceptorUserActive implements Interceptor {
  constructor (private xhrs: Xhr[], private userContext: UserContext) {
    console.log('Se ha creado el interceptor para la actividad del usuario')
  }

  execute (): void {
    this.xhrs.forEach(xhr => {
      const axiosInstance = xhr.getAxios()

      axiosInstance.interceptors.request.use(config => {
        if (this.isRefreshRequest(config)) {
          return config
        }
        console.log('[InterceptorUserActive] - El usuario ha realizado una llamada y esta activo.')
        this.userContext.active = true
        return config
      })
    })
  }

  isRefreshRequest (config: AxiosRequestConfig): boolean {
    return config.url && config.url === env.getEnvVariable('VUE_APP_REFRESH_URL')
  }
}
