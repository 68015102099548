const disabled = {
  props: {
    disabled: Boolean
  },
  data: function () {
    return {
      disabledData: this.disabled
    }
  },
  watch: {
    disabled (newVal) {
      this.disabledData = newVal
    }
  },
  methods: {
    setDisabled (value) {
      this.disabledData = value
    },
    isDisabled () {
      return this.disabledData
    }
  }
}

export default disabled
