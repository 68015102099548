import downloadDocument from '@/services/transactions/downloadDocument.service'
import DownloadDocumentRequest from './models/DownloadDocumentRequest'

const executeAction = async (request: DownloadDocumentRequest) => {
  try {
    const response = await downloadDocument(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[downloadDocument] - Ha ocurrido un error al llamar a downloadDocument')
    throw err
  }
}

export default executeAction
