import { xhr } from '@/api/config/Repository'
import axios, { AxiosResponse } from 'axios'
import SearchTransactionsRequestDTO from '../dto/SearchTransactionsRequestDTO'
import SearchTransactionsResponseDTO from '../dto/SearchTransactionsResponseDTO'
import ExportTransactionsRequestDTO from '../dto/ExportTransactionsRequestDTO'
import ExportTransactionsResponseDTO from '../dto/ExportTransactionsResponseDTO'
import GetTransactionStepsRequestDTO from '../dto/GetTransactionStepsRequestDTO'
import GetTransactionStepsResponseDTO from '../dto/GetTransactionStepsResponseDTO'
import GetTransactionRequestDTO from '../dto/GetTransactionRequestDTO'
import GetTransactionResponseDTO from '../dto/GetTransactionResponseDTO'
import GetTransactionSgaimUrlResponseDTO from '../dto/GetTransactionSgaimUrlResponseDTO'
import DownloadDocumentRequestDTO from '../dto/DownloadDocumentRequestDTO'
import DownloadDocumentResponseDTO from '../dto/DownloadDocumentResponseDTO'
import DownloadActRequestDTO from '../dto/DownloadActRequestDTO'
import DownloadActResponseDTO from '../dto/DownloadActResponseDTO'
import CancelTransactionRequestDTO from '../dto/CancelTransactionRequestDTO'
import CancelTransactionResponseDTO from '../dto/CancelTransactionResponseDTO'
import ResendTransactionRequestDTO from '../dto/ResendTransactionRequestDTO'
import ResendTransactionResponseDTO from '../dto/ResendTransactionResponseDTO'
import RefreshTransactionStateRequestDTO from '../dto/RefreshTransactionStateRequestDTO'
import RefreshTransactionStateResponseDTO from '../dto/RefreshTransactionStateResponseDTO'
import TransactionsRepository from '../TransactionsRepository'
import env from '@/services/environment'
import store from '@/store'
import UpdateTransactionRequestDTO from '../dto/UpdateTransactionRequestDTO'
import UpdateTransactionResponse from '@/services/transactions/models/UpdateTransactionResponse'
import UpdateTransactionResponseDTO from '../dto/UpdateTransactionResponseDTO'

export default class TransactionsRepositoryImpl implements TransactionsRepository {
  getDefaultHeaders (): any {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
  }

  searchTransactions (request: SearchTransactionsRequestDTO): Promise<AxiosResponse<SearchTransactionsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/search'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), request, { headers })
  }

  exportTransactions (request: ExportTransactionsRequestDTO): Promise<AxiosResponse<ExportTransactionsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/search/export'
    const headers = this.getDefaultHeaders()
    const responseType = 'blob'
    return xhr.post(msPFDUrl.concat(endpoint), request, { headers, responseType })
  }

  cancelTransaction (request: CancelTransactionRequestDTO): Promise<AxiosResponse<CancelTransactionResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/cancelIfUnsigned'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), request, { headers })
  }

  resendTransaction (request: ResendTransactionRequestDTO): Promise<AxiosResponse<ResendTransactionResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.id + '/sendreminder'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), request, { headers })
  }

  refreshTransactionState (request: RefreshTransactionStateRequestDTO): Promise<AxiosResponse<RefreshTransactionStateResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.id + '/refreshState'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), request, { headers })
  }

  getTransactionSteps (request: GetTransactionStepsRequestDTO): Promise<AxiosResponse<GetTransactionStepsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.id + '/steps'
    const headers = this.getDefaultHeaders()
    const params = {
      history: request.history,
      year: request.year
    }
    return xhr.get(msPFDUrl.concat(endpoint), { headers, params })
  }

  getTransaction (request: GetTransactionRequestDTO): Promise<AxiosResponse<GetTransactionResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.id
    // if (request.evidences === true) endpoint += '?getEvidences=true'
    const headers = this.getDefaultHeaders()
    const params = {
      getEvidences: request.evidences,
      history: request.history,
      year: request.year
    }
    return xhr.get(msPFDUrl.concat(endpoint), { headers, params })
  }

  getTransactionSgaimUrl (request: GetTransactionRequestDTO): Promise<AxiosResponse<GetTransactionSgaimUrlResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.id + '/sgaimUrl'
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  downloadDocument (request: DownloadDocumentRequestDTO): Promise<AxiosResponse<DownloadDocumentResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.transaccion_id + '/documents/' + request.documento_id + '/download'
    const headers = this.getDefaultHeaders()
    const responseType = 'blob'
    const params = {
      history: request.history,
      year: request.year
    }
    return xhr.get(msPFDUrl.concat(endpoint), { headers, responseType, params })
  }

  downloadAct (request: DownloadActRequestDTO): Promise<AxiosResponse<DownloadActResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.transaccion_id + '/evidences/' + request.documento_id + '/download'
    const headers = this.getDefaultHeaders()
    const responseType = 'blob'
    const params = {
      history: request.history,
      year: request.year
    }
    return xhr.get(msPFDUrl.concat(endpoint), { headers, responseType, params })
  }

  updateTransaction (request: UpdateTransactionRequestDTO): Promise<AxiosResponse<UpdateTransactionResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/transactions/' + request.transaction_id
    const headers = this.getDefaultHeaders()
    const params = {
      user: request.user,
      request: request.request
    }
    return xhr.patch(msPFDUrl.concat(endpoint), params, { headers })
  }
}
