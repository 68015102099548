import AuthConfig from '../AuthConfig'

export default abstract class TokenService {
  protected _accessToken: string;
  protected _refreshToken: string;
  protected _expiredToken: Date | null;
  protected _intervalRefresh: number;
  protected _startRefreshInterval: number;
  protected _activeTimeout: number;
  protected _waitEventRefresh: boolean;
  protected config: AuthConfig

  constructor (config: AuthConfig) {
    this.config = config
    this._accessToken = ''
    this._refreshToken = ''
    this._expiredToken = null
    this._intervalRefresh = this.config.refreshTokenInterval
    this._startRefreshInterval = this.config.startRefreshTokenInterval
    this._activeTimeout = this.config.activeTimeout
    this._waitEventRefresh = false
  }

  set accessToken (accessToken: string) {
    this._accessToken = accessToken
    this.setAccessTokenSaved()
  }

  get accessToken (): string {
    return this._accessToken || this.getAccessTokenSaved()
  }

  set refreshToken (refreshToken: string) {
    this._refreshToken = refreshToken
    this.setRefreshTokenSaved()
  }

  get refreshToken (): string {
    return this._refreshToken || this.getRefreshTokenSaved()
  }

  set expiredToken (expiredToken: Date | null) {
    this._expiredToken = expiredToken
  }

  get expiredToken (): Date | null {
    return this._expiredToken || this.getExpiredTokenSaved()
  }

  get intervalRefresh (): number {
    return this._intervalRefresh * 1000
  }

  get startRefreshInterval (): number {
    return this._startRefreshInterval * 1000
  }

  get activeTimeout (): number {
    return this._activeTimeout * 1000
  }

  set waitEventRefresh (waitEventRefresh: boolean) {
    this._waitEventRefresh = waitEventRefresh
  }

  get waitEventRefresh (): boolean {
    return this._waitEventRefresh
  }

  removeAll () {
    this._accessToken = ''
    this._refreshToken = ''
    this._expiredToken = null
    this._removeAll()
  }

  protected abstract _removeAll(): void

  protected abstract getAccessTokenSaved(): string
  protected abstract getRefreshTokenSaved(): string
  protected abstract getExpiredTokenSaved(): Date | null

  protected abstract setAccessTokenSaved(): void
  protected abstract setRefreshTokenSaved(): void
}
