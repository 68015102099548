
import Vue from 'vue'
import BasicDialog from '@/components/dialogs/BasicDialog.vue'
import MobileDialog from '@/components/dialogs/MobileDialog.vue'
import updateUserGDPR from '@/services/administration/updateUserGDPR.management'
import { mapGetters } from 'vuex'
import { FButton } from 'fwkc4-vue'

export default Vue.extend({
  name: 'InfoLegal',
  components: {
    BasicDialog,
    MobileDialog,
    FButton
  },
  props: {
    force: {
      type: Boolean,
      default: false
    },
    functionCb: {
      type: Function,
      default: null
    },
    continueCb: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      gdprAccepted: false,
      cambiar_clave: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'getUsername'
    ]),
    getEscape () {
      return !this.force
    },
    getButtonText () {
      if (this.force === true) return 'Aceptar'
      return 'Continuar'
    }
  },
  methods: {
    async onAccept () {
      if (this.force === false) {
        this.onCloseDialog()
      } else {
        this.$store.dispatch('ui/showMask')
        let response: any = null
        try {
          const params = {
            id: this.getUsername,
            acepto_gdpr: true
          }
          response = await updateUserGDPR(params)
          if (response?.fecha_gdpr !== null && response?.fecha_gdpr !== undefined) {
            this.gdprAccepted = true
            this.cambiar_clave = response.cambiar_clave
            this.onCloseDialog()
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.$store.dispatch('ui/hideMask')
        }
      }
    },
    onCloseDialog () {
      if (this.force === false || this.gdprAccepted === true) {
        this.$store.dispatch('ui/closeDialog')
        if (this.continueCb !== null && this.cambiar_clave === false) {
          // after GDPR user open the views as per usual
          this.continueCb()
        }
      }
      if (this.functionCb !== null && this.cambiar_clave === true) {
        // after GDPR user still needs to change his password
        this.functionCb()
      }
    }
  }
})
