
import Vue from 'vue'
import loading from '@/assets/images/loading_legacy.gif'
import { FMask } from 'fwkc4-vue'

export default Vue.extend({
  name: 'MaskLoading',
  components: {
    FMask
  },
  props: {
    loadText: {
      type: String,
      default: ''
    },
    showText: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading
    }
  }
})
