import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import CurrentUserRepository from '../CurrentUserRepository'
import CurrentUserDTO from '../dto/CurrentUserDTO'
import ResponseLoginDTO from '../dto/ResponseLoginDTO'
import RefreshRepository from '../RefreshRepository'
import env from '@/services/environment'

export default class AuthRepositorySSO implements RefreshRepository, CurrentUserRepository {
  /**
   * Servicio que refresca el token de la sesión
   * @return Promise<Response>: Retorna la información del servicio
   * @reject Promise<error>: Retorna un error en la promesa cuando la actualización no es correcta
   */
  refresh (accessToken: string, refreshToken: string): Promise<AxiosResponse<ResponseLoginDTO>> {
    const url = env.getEnvVariable('VUE_APP_REFRESH_URL')
    const params = {
      refreshToken: refreshToken,
      accessToken: accessToken
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    return xhr.post(url, params, { headers })
  }

  /**
   *
   */
  getCurrentUser (): Promise<AxiosResponse<CurrentUserDTO>> {
    const url = env.getEnvVariable('VUE_APP_CURRENT_USER')

    const headers = {}

    return xhr.get(url, { headers })
  }
}
