import API from '@/api'
import FlowsResponse from './models/FlowsResponse'

const executeAction = async (): Promise<FlowsResponse> => {
  try {
    const response = await API.DropdownsRepository.getFlows()
    return new FlowsResponse(response.data)
  } catch (err) {
    console.error('[getFlows] - Ha ocurrido un error al obtener getFlows')
    throw err
  }
}

export default executeAction
