import cancelTransaction from '@/services/transactions/cancelTransaction.service'
import CancelTransactionRequest from './models/CancelTransactionRequest'

const executeAction = async (request: CancelTransactionRequest) => {
  try {
    const response = await cancelTransaction(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[cancelTransaction] - Ha ocurrido un error al llamar a cancelTransaction')
    throw err
  }
}

export default executeAction
