import API from '@/api'
import DownloadDocumentResponse from './models/DownloadDocumentResponse'
import DownloadDocumentRequest from './models/DownloadDocumentRequest'

const executeAction = async (request: DownloadDocumentRequest): Promise<DownloadDocumentResponse> => {
  try {
    const response = await API.TransactionsRepository.downloadDocument(request)
    return new DownloadDocumentResponse(response)
  } catch (err) {
    console.error('[downloadDocument] - Ha ocurrido un error al obtener downloadDocument')
    throw err
  }
}

export default executeAction
