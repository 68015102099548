import Vue from 'vue'

import { FIcon } from 'fwkc4-vue'

import './styles.scss'

export default Vue.extend({
  components: {
    FIcon
  },
  data () {
    return {
      iconName: '',
      iconColor: '',
      rowIndex: -1,
      clickable: false,
      clickEvent: null
    }
  },
  mounted () {
    if (this.params.props) {
      this.iconName = this.params.props.iconName
      this.iconColor = this.params.props.iconColor
      this.rowIndex = this.params.props.rowIndex
      if (this.params.props.onClick) {
        this.clickable = true
        this.clickEvent = this.params.props.onClick
      }
    }
  },
  methods: {
    onClick () {
      this.clickEvent(this.rowIndex)
    }
  },
  template: `
  <div v-if="clickable" class="iconAction">
    <f-icon :name="iconName" :color="iconColor" size="lg" @click="onClick"/>
  </div>
  <div v-else class="iconAction">
    <f-icon :name="iconName" :color="iconColor" size="lg"/>
  </div>
  `
})
