import API from '@/api'
import ViewsResponse from './models/ViewsResponse'

const executeAction = async (): Promise<ViewsResponse> => {
  try {
    const response = await API.DropdownsRepository.getViews()
    return new ViewsResponse(response.data)
  } catch (err) {
    console.error('[getViews] - Ha ocurrido un error al obtener getViews')
    throw err
  }
}

export default executeAction
