export default class GetUserGDPRResponse {
  fecha_gdpr?: string;
  cambiar_clave?: boolean;
  activo?: boolean;

  constructor (params: any) {
    this.fecha_gdpr = params.fecha_gdpr
    this.cambiar_clave = params.cambiar_clave
    this.activo = params.activo
  }
}
