import changepwdExternalUser from '@/services/users/changepwdExternalUser.service'
import ChangePasswordRequest from './models/ChangePasswordRequest'

const executeAction = async (request: ChangePasswordRequest) => {
  try {
    const response = await changepwdExternalUser(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[changepwdExternalUser] - Ha ocurrido un error al llamar a changepwdExternalUser')
    throw err
  }
}

export default executeAction
