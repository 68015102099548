import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import env from '@/services/environment'

import { isAuth } from './validations/AuthGuard'
import { hasDoc } from './validations/DocGuard'
import { isTransactionRef, isTransactionRefAuth } from './router.middleware'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: (to, from, next) => { isTransactionRefAuth(to, from, next) }
  },
  {
    path: '/Usuarios',
    name: 'Usuarios',
    component: () => import('@/views/pages/usuarios/ListadoUsuarios.vue'),
    beforeEnter: (to, from, next) => { isAuth(to, from, next) }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue'),
    beforeEnter: (to, from, next) => { isTransactionRef(to, from, next) }
  },
  {
    path: '/forbidden',
    name: 'ForbiddenAccess',
    component: () => import('../views/forbiddenAccess/index.vue')
  },
  {
    path: '/contractM',
    name: 'ContratoMobile',
    props: true,
    component: () => import('../views/mobile/contrato/index.vue'),
    beforeEnter: (to, from, next) => { hasDoc(to, from, next) }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: env.getEnvVariable('BASE_URL'),
  routes
})

export default router
