import API from '@/api'
import GetTransactionSgaimUrlResponse from './models/GetTransactionSgaimUrlResponse'
import GetTransactionRequest from './models/GetTransactionRequest'

const executeAction = async (request: GetTransactionRequest): Promise<GetTransactionSgaimUrlResponse> => {
  try {
    const response = await API.TransactionsRepository.getTransactionSgaimUrl(request)
    return new GetTransactionSgaimUrlResponse(response.data)
  } catch (err) {
    console.error('[getTransactionSgaimUrl] - Ha ocurrido un error al obtener getTransactionSgaimUrl')
    throw err
  }
}

export default executeAction
