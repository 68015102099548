import API from '@/api'
import StepsResponse from './models/StepsResponse'
import StepsRequest from './models/StepsRequest'

const executeAction = async (request: StepsRequest): Promise<StepsResponse> => {
  try {
    const response = await API.DropdownsRepository.getSteps(request)
    return new StepsResponse(response.data)
  } catch (err) {
    console.error('[getSteps] - Ha ocurrido un error al obtener getSteps')
    throw err
  }
}

export default executeAction
