import API from '@/api'
import GetUserGDPRResponse from './models/GetUserGDPRResponse'
import GetUserGDPRRequest from './models/GetUserGDPRRequest'

const executeAction = async (request: GetUserGDPRRequest): Promise<GetUserGDPRResponse> => {
  try {
    const response = await API.AdministrationRepository.getUserGDPR(request)
    return new GetUserGDPRResponse(response.data)
  } catch (err) {
    console.error('[getUserGDPR] - Ha ocurrido un error al obtener getUserGDPR')
    throw err
  }
}

export default executeAction
