import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import CurrentUserRepository from '../CurrentUserRepository'
import CurrentUserDTO from '../dto/CurrentUserDTO'
import ResponseLoginDTO from '../dto/ResponseLoginDTO'
import LoginRepository from '../LoginRepository'
import PasswordRepository from '../PasswordRepository'
import RefreshRepository from '../RefreshRepository'
import env from '@/services/environment'

export default class AuthRepositoryImpl implements LoginRepository, CurrentUserRepository, RefreshRepository, PasswordRepository {
  /**
   * Servicio de autenticación para el usuario
   * @param username: Nombre de usuario
   * @param password: Contraseña del usuario
   * @return Promise<Response>: Retorna la información del servicio
   * @reject Promise<Error>: Retorna un error en la promesa cuando la autenticación no es correcta
   */
  login (username: string, password: string): Promise<AxiosResponse<ResponseLoginDTO>> {
    const url = env.getEnvVariable('VUE_APP_LOGIN_URL')
    const params = {
      username,
      password
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    return xhr.post(url, params, { headers })
  }

  /**
   *
   */
  getCurrentUser (): Promise<AxiosResponse<CurrentUserDTO>> {
    const url = env.getEnvVariable('VUE_APP_CURRENT_USER')

    const headers = {}

    return xhr.get(url, { headers })
  }

  /**
   * Servicio que refresca el token de la sesión
   * @return Promise<Response>: Retorna la información del servicio
   * @reject Promise<error>: Retorna un error en la promesa cuando la actualización no es correcta
   */
  refresh (accessToken: string, refreshToken: string): Promise<AxiosResponse<ResponseLoginDTO>> {
    const url = env.getEnvVariable('VUE_APP_REFRESH_URL')
    const params = {
      refreshToken: refreshToken,
      accessToken: accessToken
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    return xhr.post(url, params, { headers })
  }

  /**
   * Servicio que restaura la contraseña del usuario para que pueda introducir una nueva
   */
  resetPassword (userId: string) {
    const url = `/ui-externalUsers/externalUsers/${userId}/resetpwd`
    const headers = {
      'Content-Type': 'application/json'
    }

    return xhr.patch(url, null, { headers })
  }

  /**
   * Servicio que cambia la contraseña del usuario por una nueva después de restaurar la anterior
   */
  changePassword (userId: string, oldPassword: string, newPassword: string) {
    const url = `authentication/users/${userId}/passwd`
    const headers = {
      'Content-Type': 'application/json'
    }
    const params = {
      password: oldPassword,
      newpassword: newPassword
    }

    return xhr.patch(url, null, { headers, params })
  }
}
