import LogoutService from '../LogoutService'
import TokenService from '../TokenInfo'
import UserContext from '../UserContext'
import env from '@/services/environment'

declare const appmenu: any
export default class LogoutServiceApp implements LogoutService {
  constructor (private tokenService: TokenService, private userContext: UserContext) {
    console.log('Se ha creado el logout service de SSO')
  }

  logout (): Promise<any> {
    this.userContext.clear()
    this.tokenService.removeAll()

    if (typeof appmenu !== 'undefined' && appmenu.back) {
      appmenu.back()
    } else {
      const urlLogoutAdfs = this.createUrl()
      window.location.replace(urlLogoutAdfs)
    }

    return Promise.resolve()
  }

  private createUrl (): string {
    const urlBase = `${env.getEnvVariable('VUE_APP_SSO_LOGOUT_URL')}`
    const redirect = `?redirect_logout_uri=${window.location.href}`

    return urlBase.concat(redirect)
  }
}
