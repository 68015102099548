export default class CancelTransactionResponse {
  code?: string;
  status?: string;
  message?: string;

  constructor (params: any) {
    this.code = params.code
    this.status = params.status
    this.message = params.message
  }
}
