import { Guard, Next } from './Guard'
import { Route } from 'vue-router'

export const hasDoc: Guard = async (to: Route, from: Route, next: Next) => {
  if (!to?.params?.document) {
    next('/')
  } else {
    next()
  }
}
