import API from '@/api'
import GetTransactionStepsResponse from './models/GetTransactionStepsResponse'
import GetTransactionStepsRequest from './models/GetTransactionStepsRequest'

const executeAction = async (request: GetTransactionStepsRequest): Promise<GetTransactionStepsResponse> => {
  try {
    const response = await API.TransactionsRepository.getTransactionSteps(request)
    return new GetTransactionStepsResponse(response.data)
  } catch (err) {
    console.error('[getTransactionSteps] - Ha ocurrido un error al obtener getTransactionSteps')
    throw err
  }
}

export default executeAction
