import DepartmentDTO from '@/api/common/DepartmentDTO'
import getDepartments from '@/services/dropdowns/getDepartments.service'
import store from '@/store/index'

const executeAction = async (): Promise<DepartmentDTO[]> => {
  try {
    const response = await getDepartments()
    // pasamos los datos a la store de combos
    if (response != null) {
      const data = {
        valores: response,
        tipo: 'DEPARTMENTS'
      }
      store.dispatch('combos/updateCombo', { data })
      return response.fields
    }
  } catch (err) {
    console.error('[getDepartments] - Ha ocurrido un error al llamar a getDepartments')
    throw err
  }
}

export default executeAction
