export const isImage = (type: string) => {
  return type === 'image/jpg' || type === 'image/png' || type === 'image/bmp' || type === 'image/tiff' || type === 'image/tif' || type === 'image/jpeg'
}
export const isImageTiff = (type: string) => {
  return type === 'tiff' || type === 'tif'
}
export const isImageWhitoutTiff = (type: string) => {
  return type === 'jpg' || type === 'png' || type === 'bmp' || type === 'jpeg'
}
export const removeBase64Header = (src: any, type: string) => {
  return src.split(',').slice(1).join(',')
}
export const toBase64 = async (file: any) => {
  return await new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
