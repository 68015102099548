import downloadAct from '@/services/transactions/downloadAct.service'
import DownloadActRequest from './models/DownloadActRequest'

const executeAction = async (request: DownloadActRequest) => {
  try {
    const response = await downloadAct(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[downloadAct] - Ha ocurrido un error al llamar a downloadAct')
    throw err
  }
}

export default executeAction
