import StepDTO from '@/api/common/StepDTO'

export default class StepsResponse {
  fields?: Array<StepDTO>;

  constructor (params: any) {
    if (params && params.length > 0) {
      this.fields = Array.from(params)
    } else {
      this.fields = null
    }
  }
}
