import AuthConfig from '../../AuthConfig'
import LoginService from '../LoginService'
import TokenInfo from '../TokenInfo'
import Cookies from 'js-cookie'
import Interceptor from '../../interceptors/Interceptor'
import RefreshTokenService from '../common/RefreshTokenService'
import UserContext from '../UserContext'
import AuthServiceFactory from '@/services/auth/AuthServiceFactory'

export default class LoginServiceSSO implements LoginService {
  constructor (private refreshService: RefreshTokenService,
      private config: AuthConfig, private tokenService: TokenInfo,
      private userContext: UserContext, private interceptors: Interceptor[]) {
    console.log('Se ha creado el login service de SSO')
  }

  async login (): Promise<any> {
    const adfsLoginUrl = this.createURL()
    window.location.replace(adfsLoginUrl)

    return Promise.resolve()
  }

  async checkSession (): Promise<any> {
    if (!this.checkCookiesSSO()) {
      return Promise.resolve()
    }

    this.createCookiesApp()
    this.interceptors.forEach(interceptor => interceptor.execute())

    try {
      await this.refreshService.execute()
      const eventLogin = new Event('login-success')
      document.dispatchEvent(eventLogin)

      await this.userContext.getCurrentUser()
    } catch (e) {
      console.error('Ha ocurrido un error al realizar el login', e)
    }

    return Promise.resolve()
  }

  isLoginApp (): boolean {
    return false
  }

  private createURL (): string {
    const urlBase = `${this.config.adfsAuthUrl}`
    const appName = `?application_name=${this.config.appCode}`
    const redirect = `&redirect_uri=${this.config.redirectSuccess}`
    const error = `&error_uri=${this.config.redirectError}`

    return urlBase.concat(appName).concat(redirect).concat(error)
  }

  private checkCookiesSSO (): boolean {
    return Cookies.get(this.config.ssoAccessToken) !== undefined &&
      Cookies.get(this.config.ssoRefreshToken) !== undefined
  }

  private createCookiesApp () {
    const accessCookieSSO = Cookies.get(this.config.ssoAccessToken) || ''
    this.tokenService.accessToken = accessCookieSSO

    const refreshCookieSSO = Cookies.get(this.config.ssoRefreshToken) || ''
    this.tokenService.refreshToken = refreshCookieSSO || ''
  }

  isAuth (): boolean {
    return !!this.tokenService.accessToken || Cookies.get(this.config.ssoAccessToken) !== undefined
  }

  isAuthUser (): boolean {
    return (AuthServiceFactory.getInstance().user !== null && AuthServiceFactory.getInstance().user.username !== undefined && AuthServiceFactory.getInstance().user.username !== null && AuthServiceFactory.getInstance().user.username !== '')
  }
}
