import API from '@/api'
import ChangePasswordResponse from './models/ChangePasswordResponse'
import ChangePasswordRequest from './models/ChangePasswordRequest'

const executeAction = async (request: ChangePasswordRequest): Promise<ChangePasswordResponse> => {
  try {
    const response = await API.UsersRepository.changepwdExternalUser(request)
    return new ChangePasswordResponse(response.data)
  } catch (err) {
    console.error('[changepwdExternalUser] - Ha ocurrido un error al obtener changepwdExternalUser')
    throw err
  }
}

export default executeAction
