import API from '@/api'
import GetTransactionResponse from './models/GetTransactionResponse'
import GetTransactionRequest from './models/GetTransactionRequest'

const executeAction = async (request: GetTransactionRequest): Promise<GetTransactionResponse> => {
  try {
    const response = await API.TransactionsRepository.getTransaction(request)
    return new GetTransactionResponse(response.data)
  } catch (err) {
    console.error('[getTransaction] - Ha ocurrido un error al obtener getTransaction')
    throw err
  }
}

export default executeAction
