import updateUserGDPR from '@/services/administration/updateUserGDPR.service'
import UpdateUserGDPRRequest from './models/UpdateUserGDPRRequest'

const executeAction = async (request: UpdateUserGDPRRequest) => {
  try {
    const response = await updateUserGDPR(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[updateUserGDPR] - Ha ocurrido un error al llamar a updateUserGDPR')
    throw err
  }
}

export default executeAction
