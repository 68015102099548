import API from '@/api'
import UpdateUserGDPRResponse from './models/UpdateUserGDPRResponse'
import UpdateUserGDPRRequest from './models/UpdateUserGDPRRequest'

const executeAction = async (request: UpdateUserGDPRRequest): Promise<UpdateUserGDPRResponse> => {
  try {
    const response = await API.AdministrationRepository.updateUserGDPR(request)
    return new UpdateUserGDPRResponse(response.data)
  } catch (err) {
    console.error('[updateUserGDPR] - Ha ocurrido un error al obtener updateUserGDPR')
    throw err
  }
}

export default executeAction
