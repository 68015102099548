import { Module } from 'vuex'
import { StateUi } from './types'
import { RootStore } from '@/store/rootStore'

const getDefaultStateDialog = () => {
  return {
    open: false,
    component: '',
    props: {}
  }
}

const getDefaultStateSecondDialog = () => {
  return {
    open: false,
    component: '',
    props: {}
  }
}

const getDefaultTextMask = () => 'Cargando...'
const getDefaultMenuDrawer = () => false

const ui: Module<StateUi, RootStore> = {
  namespaced: true,
  state: {
    dialog: getDefaultStateDialog(),
    secondDialog: getDefaultStateSecondDialog(),
    mask: {
      text: getDefaultTextMask(),
      show: false
    },
    filterMask: {
      text: getDefaultTextMask(),
      show: false
    },
    openedTabs: [],
    isOpenMenuDrawer: getDefaultMenuDrawer(),
    showTopMenu: true
  },
  mutations: {
    toggleMask (state, { text, show }) {
      state.mask = {
        text: text || getDefaultTextMask(),
        show
      }
    },
    toggleFilterMask (state, { text, show }) {
      state.filterMask = {
        text: text || getDefaultTextMask(),
        show
      }
    },
    openDialog (state, { component, props }) {
      if (props?.secondModal === true) {
        state.secondDialog.open = true
        state.secondDialog.component = component
        state.secondDialog.props = props
      } else {
        state.dialog.open = true
        state.dialog.component = component
        state.dialog.props = props
      }
    },
    closeDialog (state) {
      state.dialog = getDefaultStateDialog()
    },
    closeSecondDialog (state) {
      state.secondDialog = getDefaultStateSecondDialog()
    },
    addOpenedTab (state, name) {
      state.openedTabs.push(name)
    },
    removeOpenedTab (state, name) {
      state.openedTabs.splice(state.openedTabs.findIndex(item => item === name), 1)
    },
    openMenuDrawer (state) {
      state.isOpenMenuDrawer = true
    },
    closeMenuDrawer (state) {
      state.isOpenMenuDrawer = false
    },
    toggleShowTopMenu (state, { showTopMenu }) {
      state.showTopMenu = showTopMenu
    }
  },
  actions: {
    showMask ({ commit }, { text } = {}) {
      commit('toggleMask', { text, show: true })
    },
    hideMask ({ commit }) {
      commit('toggleMask', { show: false })
    },
    showFilterMask ({ commit }, { text } = {}) {
      commit('toggleFilterMask', { text, show: true })
    },
    hideFilterMask ({ commit }) {
      commit('toggleFilterMask', { show: false })
    },
    openDialog ({ commit }, { component, props }) {
      commit('openDialog', { component, props })
    },
    closeDialog ({ commit }) {
      commit('closeDialog')
    },
    closeSecondDialog ({ commit }) {
      commit('closeSecondDialog')
    },
    addOpenedTab ({ commit }, name) {
      commit('addOpenedTab', name)
    },
    removeOpenedTab ({ commit }, name) {
      commit('removeOpenedTab', name)
    },
    openMenuDrawer ({ commit }) {
      commit('openMenuDrawer')
    },
    closeMenuDrawer ({ commit }) {
      commit('closeMenuDrawer')
    },
    showTopMenu ({ commit }) {
      commit('toggleShowTopMenu', { showTopMenu: true })
    },
    hideTopMenu ({ commit }) {
      commit('toggleShowTopMenu', { showTopMenu: false })
    }
  },
  getters: {
    maskInfo (state) {
      return state.mask
    },
    filterMaskInfo (state) {
      return state.filterMask
    },
    dialogInfo (state) {
      return state.dialog
    },
    secondDialogInfo (state) {
      return state.secondDialog
    },
    openedTabs (state) {
      return state.openedTabs
    },
    menuDrawerInfo (state) {
      return state.isOpenMenuDrawer
    },
    showTopMenu (state) {
      return state.showTopMenu
    }
  }
}

export default ui
