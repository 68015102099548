
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { FTextField, FCombobox, FButton } from 'fwkc4-vue'
import DocumentData from './DocumentData.vue'
import TextArea from '@/components/textArea/TextArea.vue'
import GenericConfirmedDialog from '@/views/common/dialogs/GenericConfirmedDialog.vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import GenericControlConfirmDialog from '@/views/common/dialogs/GenericControlConfirmDialog.vue'
import updateTransaction from '@/services/transactions/updateTransaction.management'

export default Vue.extend({
  name: 'ADCO',
  components: {
    FTextField,
    TextArea,
    FButton,
    DocumentData,
    FCombobox
  },
  props: {
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      blockTreatment: false,
      asunto: null,
      id: null,
      motivo: null,
      metadatos: null,
      estado: null,
      obs: null,
      obsAssortment: null,
      obsProvider: null,
      treatment: null,
      treatmentCB: [],
      codProvider: null,
      cifProvider: null,
      section: null
    }
  },
  computed: {
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    ...mapGetters('user', [
      'isUsuarioExterno',
      'getUsername',
      'isSindicato'
    ]),
    hasFlows () {
      return true
    },
    getTreatmentDisabled () {
      if (this.getCurrentTransaction?.estado?.estadoInterno === 'PENDIENTE') return true
      // if (this.blockTreatment !== true) return true
      if (this.treatment === 'Tratado') return true
      return false
    },
    getContratosAdHocFields () {
      // const curr_estado = this.getCurrentTransaction?.estado?.estadoInterno
      // const flow = this.getCurrentTransaction?.flujo_id
      // if (curr_estado === 'FIRMADO' && flow === 95) return true
      // return false
      return true
    }
  },
  async mounted () {
    try {
      this.$store.dispatch('ui/showMask')
      this.setInitialData()
    } catch (err) {
      console.log(err)
    } finally {
      this.$store.dispatch('ui/hideMask')
    }
  },
  methods: {
    emitChangeEventFromCbo (value) {
      this.$emit('onChangeAdco', {
        obs: this.obs,
        obsAssortment: this.obsAssortment,
        obsProvider: this.obsProvider,
        treatment: value
      })
    },
    emitChangeEvent () {
      this.$emit('onChangeAdco', {
        obs: this.obs,
        obsAssortment: this.obsAssortment,
        obsProvider: this.obsProvider,
        treatment: this.treatment
      })
    },
    setInitialData () {
      // hardcodeado
      this.treatmentCB = [
        'Sin tratar',
        'En curso',
        'Tratado'
      ]
      const transaction = this.getCurrentTransaction
      if (transaction !== null) {
        this.asunto = transaction.asunto
        this.estado = transaction.estado.estadoInterno
        this.id = transaction.id
        this.motivo = transaction.motivo
        this.metadatos = transaction.metadatos
        if (transaction?.metadatos !== null && transaction?.metadatos !== undefined) {
          this.obs = transaction.metadatos.Observaciones !== undefined ? transaction.metadatos.Observaciones : null
          this.obsAssortment = transaction.metadatos.Observaciones_surtido !== undefined ? transaction.metadatos.Observaciones_surtido : null
          this.obsProvider = transaction.metadatos.Observaciones_proveedor !== undefined ? transaction.metadatos.Observaciones_proveedor : null
          this.treatment = transaction.metadatos.Tratamiento !== undefined ? transaction.metadatos.Tratamiento : null
          // this.codProvider = transaction.metadatos.Cod_Proveedor !== undefined ? transaction.metadatos.Cod_Proveedor : null
          // this.cifProvider = transaction.metadatos.CIF_Proveedor !== undefined ? transaction.metadatos.CIF_Proveedor : null
          // this.section = transaction.metadatos.Seccion !== undefined ? transaction.metadatos.Seccion : null
        }
      }
    },
    cancelChanges () {
      this.$emit('onCancelChanges')
    },
    confirmSaveChanges () {
      this.$emit('onConfirmSaveChanges')
    }
  }
})
