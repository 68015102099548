import LoginService from '../LoginService'
import AuthRepositoryApp from '@/api/auth/impl/AuthRepositoryApp'
import Interceptor from '../../interceptors/Interceptor'
import TokenService from '../TokenInfo'
import UserContext from '../UserContext'
import ResponseLoginDTO from '@/api/auth/dto/ResponseLoginDTO'
import RefreshTokenService from '../common/RefreshTokenService'

export default class LoginServiceApp implements LoginService {
  constructor (private authApi: AuthRepositoryApp, private refreshService: RefreshTokenService,
    private tokenService: TokenService, private userContext: UserContext,
    private interceptors: Interceptor[]) {
    console.log('')
  }

  async login (username: string, password: string): Promise<any> {
    const response = await this.authApi.login(username, password)

    if (!response || !response.data.access_token) {
      console.error('[LoginServiceApp] - successOperation - La respuesta del auth token no incluye un token válido.')
      throw Error('La respuestano no incluye un token válido.')
    }

    this.handleSucessLogin(response.data)
  }

  async checkSession (): Promise<any> {
    if (!this.isAuth()) {
      const event = new Event('logoutapp')
      document.dispatchEvent(event)
      return Promise.resolve()
    }

    try {
      this.interceptors.forEach(interceptor => interceptor.execute())

      await this.refreshService.execute()
      const eventLogin = new Event('login-success')
      document.dispatchEvent(eventLogin)

      await this.userContext.getCurrentUser()
    } catch (e) {
      console.error('Ha ocurrido un error al realizar el login', e)
    }

    return Promise.resolve()
  }

  isLoginApp (): boolean {
    return true
  }

  async handleSucessLogin (response: ResponseLoginDTO) {
    const access_token = response.access_token
    const refresh_token = response.refresh_token

    this.createCookieApp(access_token, refresh_token)
    this.interceptors.forEach(interceptor => interceptor.execute())

    await this.userContext.getCurrentUser()

    const event = new Event('login-success')
    document.dispatchEvent(event)
  }

  createCookieApp (access_token: string, refresh_token: string) {
    this.tokenService.accessToken = access_token
    this.tokenService.refreshToken = refresh_token
  }

  isAuth (): boolean {
    return !!this.tokenService.accessToken
  }

  isAuthUser (): boolean {
    return this.userContext.username !== undefined && this.userContext.username !== null && this.userContext.username !== ''
  }
}
