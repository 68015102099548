import AuthRepositorySSO from '@/api/auth/impl/AuthRepositorySSO'
import AuthRepositoryApp from '@/api/auth/impl/AuthRepositoryApp'
import { xhr } from '@/api/config/Repository'
import AuthConfig from './AuthConfig'
import InterceptorBearerToken from './interceptors/InterceptorBearerToken'
import InterceptorInactivity from './interceptors/InterceptorInactivity'
import InterceptorUserActive from './interceptors/InterceptorUserActive'
import LoginService from './modules/LoginService'
import LogoutService from './modules/LogoutService'
import RefreshService from './modules/RefreshService'
import LoginServiceSSO from './modules/sso/LoginServiceSSO'
import LogoutServiceApp from './modules/app/LogoutServiceApp'
import TokenInfoCookie from './modules/common/TokenInfoCookie'
import TokenInfo from './modules/TokenInfo'
import UserContext from './modules/UserContext'
import LoginServiceApp from './modules/app/LoginServiceApp'
import RefreshTokenService from './modules/common/RefreshTokenService'
import LogoutServiceSSO from './modules/sso/LogoutServiceSSO'
import env from '@/services/environment'

export interface AuthServiceType {
  login: LoginService;
  token: TokenInfo;
  logout: LogoutService;
  refresh: RefreshService;
  user: UserContext;
}

export default class AuthServiceFactory {
  private static authService: AuthServiceType = null as any;

  static getInstance (): AuthServiceType {
    const config = this.createConfig()

    if (!this.authService) {
      this.authService = this.createInstance(config)
    }

    return this.authService
  }

  static createInstance (config: AuthConfig): AuthServiceType {
    if (config.authenticationType === 'sso') {
      return this.createAuthSSO(config)
    } else if (config.authenticationType === 'app') {
      return this.createAuthApp(config)
    } else {
      throw new Error('The authenticationType not is correct')
    }
  }

  static createAuthApp (config: AuthConfig): AuthServiceType {
    const authRepository = new AuthRepositoryApp()
    const userContext = new UserContext(authRepository)
    const tokenServiceApp = new TokenInfoCookie(config)
    const logoutService = new LogoutServiceApp(tokenServiceApp, userContext)
    const refreshService = new RefreshTokenService(authRepository, tokenServiceApp, userContext)

    const interceptorInactivity = new InterceptorInactivity(tokenServiceApp, userContext)
    const interceptorBearerToken = new InterceptorBearerToken([xhr], tokenServiceApp)
    const interceptorUserActive = new InterceptorUserActive([xhr], userContext)
    const interceptors = [interceptorBearerToken, interceptorInactivity, interceptorUserActive]

    const loginService = new LoginServiceApp(authRepository, refreshService, tokenServiceApp, userContext, interceptors)

    return {
      login: loginService,
      token: tokenServiceApp,
      logout: logoutService,
      refresh: refreshService,
      user: userContext
    }
  }

  static createAuthSSO (config: AuthConfig): AuthServiceType {
    const authRepository = new AuthRepositorySSO()
    const userContext = new UserContext(authRepository)
    const tokenServiceSSO = new TokenInfoCookie(config)
    const logoutService = new LogoutServiceSSO(tokenServiceSSO, userContext)
    const refreshService = new RefreshTokenService(authRepository, tokenServiceSSO, userContext)

    const interceptorInactivity = new InterceptorInactivity(tokenServiceSSO, userContext)
    const interceptorBearerToken = new InterceptorBearerToken([xhr], tokenServiceSSO)
    const interceptorUserActive = new InterceptorUserActive([xhr], userContext)
    const interceptors = [interceptorBearerToken, interceptorInactivity, interceptorUserActive]

    const loginService = new LoginServiceSSO(refreshService, config, tokenServiceSSO, userContext, interceptors)

    return {
      login: loginService,
      token: tokenServiceSSO,
      logout: logoutService,
      refresh: refreshService,
      user: userContext
    }
  }

  static createConfig (): AuthConfig {
    const urlErroByDefault = window.location.origin + '/forbidden'
    const refreshTokenInterval = env.getEnvVariable('VUE_APP_REFRESH_TOKEN_INTERVAL')
    const startRefreshTokenInterval = env.getEnvVariable('VUE_APP_START_REFRESH_TOKEN_INTERVAL')
    const activeTimeout = env.getEnvVariable('VUE_APP_ACTIVE_TIMEOUT')

    return {
      authenticationType: env.getEnvVariable('VUE_APP_AUTHENTICATION_TYPE'),
      adfsAuthUrl: env.getEnvVariable('VUE_APP_ADFS_URL'),
      ssoAccessToken: env.getEnvVariable('VUE_APP_SSO_ACCESS_TOKEN'),
      ssoRefreshToken: env.getEnvVariable('VUE_APP_SSO_REFRESH_TOKEN'),
      appAccessToken: env.getEnvVariable('VUE_APP_APP_ACCESS_TOKEN'),
      appRefreshToken: env.getEnvVariable('VUE_APP_APP_REFRESH_TOKEN'),
      appCode: env.getEnvVariable('VUE_APP_APP_CODE'),
      redirectSuccess: window.location.href,
      redirectError: env.getEnvVariable('VUE_APP_REDIRECT_ERROR') || urlErroByDefault,
      refreshTokenInterval: Number(refreshTokenInterval),
      startRefreshTokenInterval: startRefreshTokenInterval ? Number(startRefreshTokenInterval) : 30,
      activeTimeout: activeTimeout ? Number(activeTimeout) : 600
    }
  }
}
