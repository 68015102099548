import AdministracionFactory from './administracion/AdministracionFactory'
import AdministrationFactory from './administration/AdministrationFactory'
import FlowsFactory from './flows/FlowsFactory'
import UsersFactory from './users/UsersFactory'
import TransactionsFactory from './transactions/TransactionsFactory'
import DropdownsFactory from './dropdowns/DropdownsFactory'

const API = {
  AdministrationRepository: AdministrationFactory.getInstance(),
  AdministracionRepository: AdministracionFactory.getInstance(),
  FlowsRepository: FlowsFactory.getInstance(),
  UsersRepository: UsersFactory.getInstance(),
  TransactionsRepository: TransactionsFactory.getInstance(),
  DropdownsRepository: DropdownsFactory.getInstance()
}
export default API
