import LogoutService from '../LogoutService'
import TokenService from '../TokenInfo'
import UserContext from '../UserContext'

export default class LogoutServiceApp implements LogoutService {
  constructor (private tokenService: TokenService, private userContext: UserContext) {
    console.log('Se ha creado el logout service de SSO')
  }

  logout (): Promise<any> {
    this.userContext.clear()
    this.tokenService.removeAll()

    const event = new Event('logout-success')
    document.dispatchEvent(event)

    return Promise.resolve()
  }
}
