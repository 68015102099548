import API from '@/api'
import UpdateTransactionRequest from './models/UpdateTransactionRequest'
import UpdateTransactionResponse from './models/UpdateTransactionResponse'

const executeAction = async (request: UpdateTransactionRequest): Promise<UpdateTransactionResponse> => {
  try {
    const response = await API.TransactionsRepository.updateTransaction(request)
    return new UpdateTransactionResponse(response.data)
  } catch (err) {
    console.error('[updateTransaction] - Ha ocurrido un error al llamar a updateTransaction')
    throw err
  }
}

export default executeAction
