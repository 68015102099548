import Xhr from './Xhr'
import env from '@/services/environment'

const baseUrl = env.getEnvVariable('VUE_APP_ROOT_API')
const clientId = env.getEnvVariable('VUE_APP_X_IBM_CLIENT_ID')
const clientSecret = env.getEnvVariable('VUE_APP_CLIENT_SECRET')

const xhr = Xhr.builder()
  .setBaseUrl(baseUrl)
  .setHeaders({
    'x-ibm-client-id': clientId,
    'x-ibm-client-secret': clientSecret
  })
  .build()

export {
  xhr
}
