import { Guard, Next } from './Guard'
import { Route } from 'vue-router'
import AuthServiceFactory from '@/services/auth/AuthServiceFactory'

export const isAuth: Guard = async (to: Route, from: Route, next: Next) => {
  const isUserAuthenticate = AuthServiceFactory.getInstance().login.isAuth()
  const isUserSessionAuthenticate = AuthServiceFactory.getInstance().login.isAuthUser()
  if (isUserAuthenticate && isUserSessionAuthenticate) {
    next()
  } else if (isUserAuthenticate && !isUserSessionAuthenticate) {
    if (to.path !== '/') {
      next('/')
    } else {
      next()
    }
  } else {
    next('login')
  }
}
