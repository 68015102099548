
import Vue from 'vue'
import Button from '@/components/Button.vue'
import { FDialog } from 'fwkc4-vue'

export default Vue.extend({
  name: 'BasicDialog',
  components: {
    Button,
    FDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Cerrar'
    },
    buttonText2: {
      type: String,
      default: 'Continuar'
    },
    hasButtons: {
      type: Boolean,
      default: true
    },
    twoButtons: {
      type: Boolean,
      default: false
    },
    disablePrimary: {
      type: Boolean,
      default: false
    },
    escape: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value () {
      const dialog: any = this.$refs.dialog
      dialog.toogleOpen()
    }
  },
  mounted () {
    if (this.value) {
      const dialog: any = this.$refs.dialog
      dialog.toogleOpen()
    }
  },
  methods: {
    primary () {
      this.$emit('primary')
    },
    secondary () {
      this.$emit('secondary')
    },
    onClose () {
      this.$emit('closeDialog')
    }
  }
})
