import { Module } from 'vuex'
import { StateTransactionDetail } from './types'
import { RootStore } from '@/store/rootStore'

export const transactionDetail: Module<StateTransactionDetail, RootStore> = {
  namespaced: true,
  state: {
    transaction: null
  },
  mutations: {
    updateCurrentTransaction (state, transaction) {
      state.transaction = transaction
    }
  },
  actions: {
    updateCurrentTransaction ({ commit }, transaction) {
      commit('updateCurrentTransaction', transaction)
    }
  },
  getters: {
    getCurrentTransaction: (state) => {
      return state.transaction
    }
  }
}
