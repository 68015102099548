import RefreshRepository from '@/api/auth/RefreshRepository'
import RefreshService from '../RefreshService'
import TokenService from '../TokenInfo'
import UserContext from '../UserContext'

export default class RefreshTokenService implements RefreshService {
  constructor (private refreshRepository: RefreshRepository, private tokenService: TokenService,
    private userContext: UserContext) {
    console.log('')
  }

  async execute (): Promise<void> {
    try {
      const response = await this.refreshRepository.refresh(this.tokenService.accessToken, this.tokenService.refreshToken)

      if (!response || !response.data.access_token) {
        console.error('[RefreshServiceApp] - successOperation - La respuesta del refresh token no incluye un token válido.')
        throw Error('La respuestano no incluye un token válido.')
      }

      const { access_token, refresh_token } = response.data

      this.tokenService.accessToken = access_token
      this.tokenService.refreshToken = refresh_token

      this.userContext.active = false

      const event = new Event('refreshtoken-success')
      document.dispatchEvent(event)
    } catch (err) {
      const status = err.response ? err.response.status : null
      this.handleErrorStatus(status)
    }
  }

  handleErrorStatus (status: number) {
    switch (status) {
      case 400: {
        const event = new Event('forbiddenaccess')
        document.dispatchEvent(event)
        break
      }

      case 401: {
        const event = new Event('logoutapp')
        document.dispatchEvent(event)
        break
      }

      case 503: {
        if (!this.userContext.lastLogin) {
          const event = new Event('warninvalidrefresh')
          document.dispatchEvent(event)
        }
        break
      }

      default: {
        if (!this.userContext.lastLogin) {
          const event = new Event('failedrefresh')
          document.dispatchEvent(event)
        }
      }
    }
  }
}
