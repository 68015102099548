import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import Vuelidate from 'vuelidate'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import VueMobileDetection from 'vue-mobile-detection'

import { FDialog, FBasicDialog, FConfirmDialog } from 'fwkc4-vue'

import './assets/styles/main.scss'

export const $bus = new Vue()
Vue.config.productionTip = false

Vue.component('f-dialog', FDialog)
Vue.component('f-basic-dialog', FBasicDialog)
Vue.component('f-confirm-dialog', FConfirmDialog)

Vue.use(Vuelidate)
Vue.use(VueMobileDetection)

dom.watch()
library.add(fas as any, far as any)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
