
import Vue from 'vue'
import AlertDialog from '@/components/dialogs/AlertDialog.vue'

export default Vue.extend({
  name: 'GenericErrorDialog',
  components: {
    AlertDialog
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    functionCb: {
      type: Function,
      default: null
    },
    secondModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    primary () {
      if (this.secondModal === true) {
        this.$emit('closeSecond')
      } else {
        this.$emit('closed')
      }
      if (this.functionCb !== null) {
        this.functionCb()
      }
    }
  }
})
