import getTransactionSteps from '@/services/transactions/getTransactionSteps.service'
import GetTransactionStepsRequest from './models/GetTransactionStepsRequest'

const executeAction = async (request: GetTransactionStepsRequest) => {
  try {
    const response = await getTransactionSteps(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[getTransactionSteps] - Ha ocurrido un error al llamar a getTransactionSteps')
    throw err
  }
}

export default executeAction
