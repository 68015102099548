import UpdateTransactionRequest from './models/UpdateTransactionRequest'
import UpdateTransaction from '@/services/transactions/updateTransaction.service'

const executeAction = async (request: UpdateTransactionRequest) => {
  try {
    const response = await UpdateTransaction(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[updateTransaction] - Ha ocurrido un error al llamar a updateTransaction')
    throw err
  }
}

export default executeAction
