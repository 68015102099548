/**
 * Funciones atajos para validar una ruta basado en vue-router
 */

import { NavigationGuard } from 'vue-router'
import { isAuth } from './validations/AuthGuard'
import store from '@/store'

export const getUrlParam = (param: string) => {
  const url = new URL(window.location.href)
  const paramValue = url.searchParams.get(param)
  return paramValue
}

// Comprobamos si se existe una referencia a un documento en la url
export const isTransactionRef: NavigationGuard = async (to, from, next) => {
  try {
    const ref = getUrlParam('ref')
    const history = getUrlParam('history')
    const year = getUrlParam('year')
    if (ref && history && year) {
      const transactionRef = {
        ref: ref,
        history: history,
        year: year
      }
      // Actualizamos la referencia del documento
      store.dispatch('transactionRef/updateTransactionRef', transactionRef)
    }
    next()
  } catch (err) {
    console.error(err)
  }
}

// Comprobamos si se existe una referencia a un documento en la url y pasamos al AuthGuard
export const isTransactionRefAuth: NavigationGuard = async (to, from, next) => {
  try {
    const ref = getUrlParam('ref')
    const history = getUrlParam('history')
    const year = getUrlParam('year')
    if (ref && history && year) {
      const transactionRef = {
        ref: ref,
        history: history,
        year: year
      }
      // Actualizamos la referencia del documento
      store.dispatch('transactionRef/updateTransactionRef', transactionRef)
    }
    isAuth(to, from, next)
  } catch (err) {
    console.error(err)
  }
}
