/**
 * Modelo para el usuario logueado o por loguear
 * Una clase User que posee los datos del logueo de usuario.
 * Tiene métodos para acortar el obtener información y así
 * reducir la verbosidad del código.
 *
 * Una instancia de la clase (singleton) es incluida en el Vuex
 * cuando se loguea.
 */

import CurrentUserDTO, { Resource } from '@/api/auth/dto/CurrentUserDTO'

// import { lowerCaseAllWordsExceptFirstLetters, getUserData, getFullToken } from '../utils'

export class User {
  data: any;
  static instance: User | null = null;
  application: string;
  attributes: Record<string, any>;
  username: string;
  jti: string;
  fullname: string;
  lastLogin: string;
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresIn: string;
  profiles: string[];
  resources: Resource[];

  // "scope": "UPT20001",
  // "application": "T2",
  // "attributes": {},
  // "username": "ADMIN001",
  // "jti": "fd41cfa1-9696-4939-b079-b595097df05f",
  // "fullname": "GENERICO ADMIN001",
  // "lastLogin": "2022-05-11T12:32:00",
  // "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TG9naW4iOiIyMDIyLTA1LTExVDEyOjMyOjAwIiwibG9nX2lkIjo5MDAwNzEsInVzZXJfbmFtZSI6IkFETUlOMDAxIiwiYXV0aGVudGljYXRpb25fdHlwZSI6ImxkYXAiLCJjbGllbnRfaWQiOiJhbm9ueW1vdXMiLCJhcHBsaWNhdGlvbiI6IlQyIiwic2NvcGUiOlsiVVBUMjAwMDEiXSwiZXhwaXJlX2F0IjoxNjUyMjcwNzUxLCJhdHRyaWJ1dGVzIjp7fSwiZnVsbG5hbWUiOiJHRU5FUklDTyBBRE1JTjAwMSIsImV4cCI6MTY1MjI3MDc1MSwiZXhwaXJlc19pbiI6NTQwMCwianRpIjoiZmQ0MWNmYTEtOTY5Ni00OTM5LWIwNzktYjU5NTA5N2RmMDVmIiwidXNlcm5hbWUiOiJBRE1JTjAwMSJ9.LYi0dN6DYtZ5Nq0122nuz3TkpONXCcgjJ4AoQN_MPzPQg4klFzkGd4KEdK5HfsHZjM3hG6W-8ZJM--aatN-t4W6EIh_eO0C8rW0OMIUl_b-CLjbVJkO0C8GB3yrFR3IOjMp9SoH4abvoHMieC7aDsdGDxRUACOBV-oOzqWBc4CY",
  // "token_type": "bearer",
  // "refresh_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TG9naW4iOiIyMDIyLTA1LTExVDEyOjMyOjAwIiwibG9nX2lkIjo5MDAwNzEsInVzZXJfbmFtZSI6IkFETUlOMDAxIiwiYXV0aGVudGljYXRpb25fdHlwZSI6ImxkYXAiLCJjbGllbnRfaWQiOiJhbm9ueW1vdXMiLCJhcHBsaWNhdGlvbiI6IlQyIiwic2NvcGUiOlsiVVBUMjAwMDEiXSwiYXRpIjoiZmQ0MWNmYTEtOTY5Ni00OTM5LWIwNzktYjU5NTA5N2RmMDVmIiwiZXhwaXJlX2F0IjoxNjUyMjcwNzUxLCJhdHRyaWJ1dGVzIjp7fSwiZnVsbG5hbWUiOiJHRU5FUklDTyBBRE1JTjAwMSIsImV4cCI6MTY1MjM1MTc1MSwiZXhwaXJlc19pbiI6NTQwMCwianRpIjoiYTBkMTc5YTYtMThhMS00MGEzLTlhODEtMDk2ODZiZjRiMjI1IiwidXNlcm5hbWUiOiJBRE1JTjAwMSJ9.OVBdnwudWNgpKR1wSb_X5JRZbMP9fOtM8F620weBqU_J4F7dF8x-ues3IxL7gnbd0TMJpIWg3J88ebj2rYXPbm3MhxNkHQprql0ZaYzn5rRik7oEXtxv8TifX-dQ-d5CzEvpsdjDw0SRlFPBM06W8pz5fBXLt1iR-HU_IOGdbbs",
  // "expires_in": 5400

  /**
    * @param {Object} [data] datos del usuario logueado (optional)
    */
  constructor (data?: CurrentUserDTO) {
    // usamos un patrón singleton
    // si ya hay una instancia del usuario,
    // establecer posible nuevos datos
    // y devolverla
    if (User.instance) {
      // User.instance.setData(data)
      // return User.instance
    }
    // en caso de no existir, establecemos
    // posibles datos, guardamos la instancia
    // y la devolvemos
    this.setData(data)
    User.instance = this
    return this
  }

  /**
    * establecer datos del usuario en la
    * propiedad `data` de la clase
    * @param [data] objeto del usuario (usualmente, la respuesta de la llamada de loguear usuario)
    */
  setData (data?: CurrentUserDTO | null) {
    this.data = data

    this.profiles = data?.profiles || ['']
    this.username = data?.username || ''
  }

  saveData () {
    window.localStorage.setItem('UserData', JSON.stringify(this.data))
  }

  removeData () {
    this.setData(null)
    this.removeDataFromStorage()
  }

  removeDataFromStorage () {
    window.localStorage.removeItem('UserData')
  }

  getProfile () {
    return (this.data?.profiles?.[0])
  }

  getProfiles () {
    return (this.data?.profiles)
  }

  getUsername () {
    return (this.data?.username)
  }

  getUserFlujos () {
    if (this.isAdmin()) return 'all'
    if (this.isUsuarioExterno) return 'none'
    return (this.data?.attributes[1]) // TODO: pendiente codigo definitivo
  }

  getUserSAP () {
    return (this.data?.attributes[550])
  }

  getUserAllowedFlows () {
    if (this.data?.attributes && this.data.attributes[8000] && this.data.attributes[8000].length > 0 && this.data.attributes[8000][0] !== '#$#$') {
      return (this.data?.attributes[8000])
    }
    return 'all'
  }

  getUserAllowedDepartments () {
    if (this.data?.attributes && this.data.attributes[8004] && this.data.attributes[8004].length > 0 && this.data.attributes[8004][0] !== '#$#$') {
      return (this.data?.attributes[8004])
    }
    return 'all'
  }

  hasPanicButton () {
    if (this.data?.attributes &&
        this.data.attributes[8001] &&
        this.data.attributes[8001].length > 0) {
      return this.data?.attributes[8001][0] === 'S'
    }
  }

  hasActs () {
    if (this.data?.attributes &&
      this.data.attributes[8002] &&
      this.data.attributes[8002].length > 0) {
      return this.data?.attributes[8002][0] === 'S'
    }
  }

  isAdmin () {
    return this.data?.profiles?.includes('UPFI0001')
  }

  isAdminFlujo () {
    return this.data?.profiles?.includes('UPFI0002')
  }

  isUsuarioInterno () {
    return this.data?.profiles?.includes('UPFI0003')
  }

  isUsuarioExterno () {
    return this.data?.profiles?.includes('UPFI0004')
  }

  isSindicato () {
    return this.data?.profiles?.includes('UPFI0005')
  }

  isResponsableRRHH () {
    return this.data?.profiles?.includes('UPFI0006')
  }

  isADCO () {
    return this.data?.profiles?.includes('UPFI0007')
  }

  isAdministradorUsuarios () {
    return this.data?.profiles?.includes('UPFI0008')
  }

  isConsultaFlujos () {
    return this.data?.profiles?.includes('UPFI0009')
  }

  isLogin () {
    return !!window.localStorage.getItem('UserData')
  }
}

export default User
