import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import env from '@/services/environment'

Vue.use(VueI18n)

/**
 * Método que fusiona los mensajes locales del proyecto con los de la libreria de componentes
 * @param messagesLocals
 */
function mergeMessagesC4Vue (messagesLocals: LocaleMessages) {
  // eslint-disable-next-line
  const { messages } = require('fwkc4-vue')
  const newMessages: LocaleMessages = {}

  Object.keys(messagesLocals).forEach((key: string) => {
    newMessages[key] = { ...messages[key], ...messagesLocals[key] }
  })

  return newMessages
}

/**
 * Método que carga los mensajes de los archivos locales en el i18n del sistema
 */
function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('./locals', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return mergeMessagesC4Vue(messages)
}

export default new VueI18n({
  locale: env.getEnvVariable('VUE_APP_I18N_LOCALE') || 'es',
  fallbackLocale: env.getEnvVariable('VUE_APP_I18N_FALLBACK_LOCALE') || 'es',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})
