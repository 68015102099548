import Vue from 'vue'
import Vuex from 'vuex'

import ui from './modules/ui/index'
import { user, combos, controlAccesoMenu, transactionDetail, transactionRef } from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui,
    user,
    combos,
    transactionDetail,
    controlAccesoMenu,
    transactionRef
  }
})
