import StepDTO from '@/api/common/StepDTO'
import getSteps from '@/services/dropdowns/getSteps.service'
import StepsRequest from './models/StepsRequest'
import store from '@/store/index'

const executeAction = async (request: StepsRequest): Promise<StepDTO[]> => {
  try {
    const response = await getSteps(request)
    // pasamos los datos a la store de combos
    if (response != null) {
      const data = {
        valores: response,
        tipo: 'STEPS',
        extraParams: request.flowId
      }
      store.dispatch('combos/updateCombo', { data })
      return response.fields
    }
  } catch (err) {
    console.error('[getSteps] - Ha ocurrido un error al llamar a getSteps')
    throw err
  }
}

export default executeAction
