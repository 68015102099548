import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import GetFlowRequestDTO from '../dto/GetFlowRequestDTO'
import GetFlowResponseDTO from '../dto/GetFlowResponseDTO'
import CreateFlowRequestDTO from '../dto/CreateFlowRequestDTO'
import CreateFlowResponseDTO from '../dto/CreateFlowResponseDTO'
import DeleteFlowRequestDTO from '../dto/DeleteFlowRequestDTO'
import DeleteFlowResponseDTO from '../dto/DeleteFlowResponseDTO'
import SearchFlowResponseDTO from '../dto/SearchFlowResponseDTO'
import SearchFlowRequestDTO from '../dto/SearchFlowRequestDTO'
import UpdateFlowResponseDTO from '../dto/UpdateFlowResponseDTO'
import UpdateFlowRequestDTO from '../dto/UpdateFlowRequestDTO'
import FlowsRepository from '../FlowsRepository'
import env from '@/services/environment'
import store from '@/store'

export default class FlowsRepositoryImpl implements FlowsRepository {
  getDefaultHeaders (): any {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
  }

  createFlow (params: CreateFlowRequestDTO): Promise<AxiosResponse<CreateFlowResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/flows'
    const headers = this.getDefaultHeaders()
    const body = {
      user: params.user,
      request: {
        nombre: params?.nombre,
        departamento_id: params?.departamento_id,
        metadatos_lista: params?.metadatos_lista,
        metadatos_fecha: params?.metadatos_fecha,
        metadatos_numerico: params?.metadatos_numerico
      }
    }
    return xhr.post(msPFDUrl.concat(endpoint), body, { headers })
  }

  getFlow (params: GetFlowRequestDTO): Promise<AxiosResponse<GetFlowResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/flows/' + params?.id
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  deleteFlow (params: DeleteFlowRequestDTO): Promise<AxiosResponse<DeleteFlowResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/flows/' + params?.id
    const headers = this.getDefaultHeaders()
    return xhr.delete(msPFDUrl.concat(endpoint), { headers })
  }

  searchFlows (params: SearchFlowRequestDTO): Promise<AxiosResponse<SearchFlowResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/flows/search'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), params, { headers })
  }

  updateFlow (params: UpdateFlowRequestDTO): Promise<AxiosResponse<UpdateFlowResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/flows/' + params?.flujo_id
    const headers = {
      'Content-Type': 'application/merge-patch+json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
    const body = {
      user: params.user,
      request: {
        nombre: params?.nombre,
        departamento_id: params?.departamento_id,
        metadatos_lista: params?.metadatos_lista,
        metadatos_fecha: params?.metadatos_fecha,
        metadatos_numerico: params?.metadatos_numerico
      }
    }
    return xhr.patch(msPFDUrl.concat(endpoint), body, { headers })
  }
}
