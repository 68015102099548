import { Module } from 'vuex'
import { StateCombos } from './types'
import { RootStore } from '@/store/rootStore'
import store from '@/store'
import getDepartments from '@/services/dropdowns/getDepartments.management'
import getFlows from '@/services/dropdowns/getFlows.management'
import getViews from '@/services/dropdowns/getViews.management'
import getSteps from '@/services/dropdowns/getSteps.management'
import getStates from '@/services/dropdowns/getStates.management'
import { logoutGenericError } from '@/utils/utils'

export const combos: Module<StateCombos, RootStore> = {
  namespaced: true,
  state: {
    departments: [],
    departmentsLoaded: false,
    flows: [],
    flowsLoaded: false,
    steps: [],
    stepsLoaded: false,
    stepsLoadedFilteredBy: 0,
    views: [],
    viewsLoaded: false,
    states: [],
    statesLoaded: false
  },
  mutations: {
    saveCombo (state, { data }) {
      switch (data.tipo) {
        case 'DEPARTMENTS': {
          state.departments.length = 0
          if (data.valores?.fields?.length > 0) {
            data.valores.fields.forEach((item: any) => state.departments.push(Object.assign({}, item)))
          }
          state.departmentsLoaded = true
          break
        }
        case 'FLOWS': {
          state.flows.length = 0
          if (data.valores?.fields?.length > 0) {
            data.valores.fields.forEach((item: any) => state.flows.push(Object.assign({}, item)))
          }
          state.flowsLoaded = true
          break
        }
        case 'STEPS': {
          state.steps.length = 0
          if (data.valores?.fields?.length > 0) {
            data.valores.fields.forEach((item: any) => state.steps.push(Object.assign({}, item)))
          }
          state.stepsLoaded = true
          state.stepsLoadedFilteredBy = data.extraParams
          break
        }
        case 'VIEWS': {
          state.views.length = 0
          if (data.valores?.fields?.length > 0) {
            data.valores.fields.forEach((item: any) => state.views.push(Object.assign({}, item)))
          }
          state.viewsLoaded = true
          break
        }
        case 'STATES': {
          state.states.length = 0
          if (data.valores?.fields?.length > 0) {
            data.valores.fields.forEach((item: any) => state.states.push(Object.assign({}, item)))
          }
          state.statesLoaded = true
          break
        }
      }
    }
  },
  actions: {
    async getCombos ({ commit, state }, { paramsList, extraParams }) {
      const results = {
        departments: [],
        flows: [],
        steps: [],
        views: [],
        states: []
      }

      if (extraParams?.loadingFromFilter) {
        store.dispatch('ui/showFilterMask', {
          text: 'Cargando...'
        })
      } else {
        store.dispatch('ui/showMask', {
          text: 'Cargando...'
        })
      }
      await Promise.all(paramsList.map(async param => {
        switch (param) {
          case 'DEPARTMENTS': {
            if (state.departmentsLoaded) {
              results.departments = state.departments
            } else {
              try {
                results.departments = await getDepartments()
              } catch (err) {
                logoutGenericError('Error al obtener el filtro de departamentos')
              }
            }
            break
          }
          case 'FLOWS': {
            if (state.flowsLoaded) {
              results.flows = state.flows
            } else {
              try {
                results.flows = await getFlows()
              } catch (err) {
                logoutGenericError('Error al obtener el filtro de flujos')
              }
            }
            break
          }
          case 'STEPS': {
            if (state.stepsLoaded && extraParams.flowId === state.stepsLoadedFilteredBy) {
              results.steps = state.steps
            } else {
              try {
                const params = {
                  flowId: extraParams.flowId
                }
                results.steps = await getSteps(params)
              } catch (err) {
                logoutGenericError('Error al obtener el filtro de pasos')
              }
            }
            break
          }
          case 'VIEWS': {
            if (state.viewsLoaded) {
              results.views = state.views
            } else {
              try {
                results.views = await getViews()
              } catch (err) {
                logoutGenericError('Error al obtener el filtro de vistas')
              }
            }
            break
          }
          case 'STATES': {
            if (state.statesLoaded) {
              results.states = state.states
            } else {
              try {
                results.states = await getStates()
              } catch (err) {
                logoutGenericError('Error al obtener el filtro de estados')
              }
            }
            break
          }
        }
      }))
      if (extraParams?.loadingFromFilter) {
        store.dispatch('ui/hideFilterMask')
      } else {
        store.dispatch('ui/hideMask')
      }

      return results
    },
    updateCombo ({ commit }, { data }) {
      commit('saveCombo', { data })
    }
  },
  getters: {
    getCombo: (state) => (param) => {
      switch (param) {
        case 'DEPARTMENTS': return state.departments
        case 'FLOWS': return state.flows
        case 'STEPS': return state.steps
        case 'VIEWS': return state.views
        case 'STATES': return state.states
      }
    },
    getCombosLoaded: (state) => {
      return {
        departmentsLoaded: state.departmentsLoaded,
        flowsLoaded: state.flowsLoaded,
        stepsLoaded: state.stepsLoaded,
        viewsLoaded: state.viewsLoaded,
        statesLoaded: state.statesLoaded
      }
    }
  }
}
