import TokenInfo from '../modules/TokenInfo'
import UserContext from '../modules/UserContext'
import Interceptor from './Interceptor'

declare const _appmenuNative: any
declare const appmenu: any

export default class InterceptorInactivity implements Interceptor {
  constructor (private tokenInfo: TokenInfo, private userContext: UserContext) {
    console.log('Se ha creado el interceptor para la inactividad del usuario')
  }

  execute (): void {
    const idInterval = setInterval(() => {
      const startRefreshIntervalMs = this.tokenInfo.startRefreshInterval
      const currentDate = new Date()
      const expiresToken = this.tokenInfo.expiredToken

      if (!expiresToken) {
        console.error('No se ha encontrado la expiración del token')
        clearInterval(idInterval)
        return
      }

      const timeExpired = expiresToken.getTime() - currentDate.getTime()

      if (timeExpired < startRefreshIntervalMs) {
        this.handleAction(idInterval, timeExpired)
      }
    }, this.tokenInfo.intervalRefresh)
  }

  handleAction (idInterval: number, timeExpired: number): void {
    if (this.userContext.active) {
      this.handleActionUserActive(timeExpired)
    } else if (timeExpired <= 0) {
      console.log('Usuario inactivo: logout')
      const event = new Event('logoutapp')
      document.dispatchEvent(event)

      clearInterval(idInterval)
    }
  }

  handleActionUserActive (timeExpired: number) {
    if (timeExpired < 0) {
      const timeInactive = this.calculateTimeInactive()

      if (timeInactive < 0) {
        this.handleTimeInactiveIs0()
        return
      }
    }

    if (typeof appmenu !== 'undefined' && appmenu.isScreenOn) {
      this.handleAppMenuAction()
    }

    if (!this.tokenInfo.waitEventRefresh) {
      const event = new Event('refreshtoken')
      document.dispatchEvent(event)
    }
  }

  handleTimeInactiveIs0 () {
    if (typeof _appmenuNative !== 'undefined' && _appmenuNative.logout) {
      _appmenuNative.logout()
    } else {
      this.tokenInfo.waitEventRefresh = false
      this.tokenInfo.removeAll()
      this.userContext.clear()

      const event = new Event('errorinvalidrefresh')
      document.dispatchEvent(event)
    }
  }

  handleAppMenuAction () {
    if (appmenu.isScreenOn()) {
      return
    }

    if (!this.tokenInfo.waitEventRefresh) {
      this.tokenInfo.waitEventRefresh = true
      appmenu.enableListenerScreenOn(true)
    } else if (this.tokenInfo.waitEventRefresh) {
      this.tokenInfo.waitEventRefresh = false
      appmenu.enableListenerScreenOn(false)
    }
  }

  calculateTimeInactive (): number {
    let timeInactive = -1

    const activeTimeoutMs = this.tokenInfo.activeTimeout
    console.log(`Tiempo active Timeout ${activeTimeoutMs}`)
    console.log(`Active date ${this.userContext.activeDate}`)
    if (this.userContext.activeDate != null && activeTimeoutMs > 0) {
      timeInactive = this.userContext.activeDate.getTime() + activeTimeoutMs - new Date().getTime()
    }

    console.log(`Time inactive ${timeInactive}`)

    return timeInactive
  }

  isNativeApp (): boolean {
    return false
  }
}
