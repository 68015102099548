import Xhr from '@/api/config/Xhr'
import TokenService from '../modules/TokenInfo'
import Interceptor from './Interceptor'

export default class InterceptorBearerToken implements Interceptor {
  constructor (private xhrs: Xhr[], private tokenService: TokenService) {
    console.log('Se ha creado el interceptor para el bearer token')
  }

  execute (): void {
    this.xhrs.forEach(xhr => {
      const axiosInstance = xhr.getAxios()

      axiosInstance.interceptors.request.use(config => {
        const token = this.tokenService.accessToken

        if (token) {
          config.headers.Authorization = 'Bearer ' + token
        }

        return config
      })
    })
  }
}
