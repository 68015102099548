import API from '@/api'
import CancelTransactionResponse from './models/CancelTransactionResponse'
import CancelTransactionRequest from './models/CancelTransactionRequest'

const executeAction = async (request: CancelTransactionRequest): Promise<CancelTransactionResponse> => {
  try {
    const response = await API.TransactionsRepository.cancelTransaction(request)
    return new CancelTransactionResponse(response.data)
  } catch (err) {
    console.error('[cancelTransaction] - Ha ocurrido un error al obtener cancelTransaction')
    throw err
  }
}

export default executeAction
