import getTransactionSgaimUrl from '@/services/transactions/getTransactionSgaimUrl.service'
import GetTransactionRequest from './models/GetTransactionRequest'

const executeAction = async (request: GetTransactionRequest) => {
  try {
    const response = await getTransactionSgaimUrl(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[getTransactionSgaimUrl] - Ha ocurrido un error al llamar a getTransactionSgaimUrl')
    throw err
  }
}

export default executeAction
