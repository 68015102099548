import { render, staticRenderFns } from "./Historico.vue?vue&type=template&id=7a0d2e32&scoped=true&name=content"
import script from "./Historico.vue?vue&type=script&lang=ts"
export * from "./Historico.vue?vue&type=script&lang=ts"
import style0 from "./Historico.vue?vue&type=style&index=0&id=7a0d2e32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0d2e32",
  null
  
)

export default component.exports