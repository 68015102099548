
import Documento from '@/views/consultas/documentos/details/Documento.vue'
import Histórico from '@/views/consultas/documentos/details/Historico.vue'
import Anexos from '@/views/consultas/documentos/details/Anexos.vue'
import ADCO from '@/views/consultas/documentos/details/ADCO.vue'

export default {
  name: 'DetailTabs',
  components: {
    Documento,
    Histórico,
    Anexos,
    ADCO
  },
  props: {
    showAdco: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabList: ['Documento', 'Histórico', 'Anexos', 'ADCO'],
      activeTab: 'Documento'
    }
  },
  watch: {
    showAdco: {
      immediate: true,
      handler (val, oldVal) {
        if (val === true) {
          this.tabList = ['Documento', 'Histórico', 'Anexos', 'ADCO']
        } else {
          this.tabList = ['Documento', 'Histórico', 'Anexos']
        }
      }
    }
  },
  methods: {
    selectTab (tabName: any) {
      this.activeTab = tabName
    },
    onChangeAnexos (data) {
      this.$emit('onChangeAnexos', data)
    },
    onChangeAdco (data) {
      this.$emit('onChangeAdco', data)
    },
    onCancelChanges () {
      this.$emit('onCancelChanges')
    },
    onConfirmSaveChanges () {
      this.$emit('onConfirmSaveChanges')
    }
  }
}
