
import TimelineItem from './TimelineItem.vue'

import Vue from 'vue'
export default Vue.extend({
  name: 'Timeline',
  components: {
    TimelineItem
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  }
})
