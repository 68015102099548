import DepartmentDTO from '@/api/common/DepartmentDTO'

export default class DepartmentsResponse {
  fields?: Array<DepartmentDTO>;

  constructor (params: any) {
    if (params && params.length > 0) {
      this.fields = Array.from(params)
    } else {
      this.fields = null
    }
  }
}
