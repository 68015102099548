import ViewDTO from '@/api/common/ViewDTO'
import getViews from '@/services/dropdowns/getViews.service'
import store from '@/store/index'

const executeAction = async (): Promise<ViewDTO[]> => {
  try {
    const response = await getViews()
    // pasamos los datos a la store de combos
    if (response != null) {
      const data = {
        valores: response,
        tipo: 'VIEWS'
      }
      store.dispatch('combos/updateCombo', { data })
      return response.fields
    }
  } catch (err) {
    console.error('[getViews] - Ha ocurrido un error al llamar a getViews')
    throw err
  }
}

export default executeAction
