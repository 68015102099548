import { Module } from 'vuex'
import { StateTransactionRef } from './types'
import { RootStore } from '@/store/rootStore'

export const transactionRef: Module<StateTransactionRef, RootStore> = {
  namespaced: true,
  state: {
    transactionRef: null
  },
  mutations: {
    updateTransactionRef (state, transactionRef) {
      state.transactionRef = transactionRef
    }
  },
  actions: {
    updateTransactionRef ({ commit }, transactionRef) {
      commit('updateTransactionRef', transactionRef)
    },
    cleanTransactionRef ({ commit }) {
      commit('updateTransactionRef', null)
    }
  },
  getters: {
    transactionRef: state => state.transactionRef,
    ref: state => state.transactionRef?.ref
  }
}
