
import { FButton } from 'fwkc4-vue'

export default {
  name: 'Button',
  components: {
    FButton
  },
  props: {
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  }
}
