
import Vue from 'vue'
import { FIcon } from 'fwkc4-vue'
import moment from 'moment'

export default Vue.extend({
  name: 'TimelineItem',
  components: {
    FIcon
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    getElementClass () {
      return this.item.element_status + '' + this.item.element_day_marker
    },
    getFecha () {
      if (this.item.fecha_firma?.length > 0) {
        return moment(new Date(this.item.fecha_firma)).format('DD/MM/YYYY HH:mm:ss')
      }
      return ''
    },
    getEmailFirmante () {
      if (this.item.id_usuario?.length > 0) {
        return ''
      }
      return this.item.email_firmante
    }
  }
})
