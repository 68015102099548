import AuthServiceFactory from '@/services/auth/AuthServiceFactory'
import { DIALOG_CONFIRM } from './types'
import store from '@/store'
import i18n from '@/i18n'

const authService = AuthServiceFactory.getInstance()

export const DIALOG_LOGOUT = {
  component: DIALOG_CONFIRM,
  props: {
    title: 'Cerrar sesión',
    text: '¿Quieres cerrar sesión?',
    buttonLabelAffirmative: 'Sí',
    buttonActionAffirmative: () => authService.logout.logout(),
    buttonLabelNegative: 'No'
  }
}

export const DIALOG_ERROR = {
  component: DIALOG_CONFIRM,
  props: {
    title: i18n.t('dialogs.title.error'),
    text: i18n.t('dialogs.text.error'),
    buttonLabelAffirmative: 'Ok',
    buttonActionAffirmative: () => {
      store.dispatch('ui/closeDialog')
    },
    buttonLabelNegative: ''
  }
}

export const DIALOG_CONFIRM_DELETE = {
  component: DIALOG_CONFIRM,
  props: {
    title: i18n.t('dialogs.title.confirmation'),
    text: i18n.t('dialogs.text.confirmDelete'),
    buttonLabelAffirmative: 'Sí',
    buttonActionAffirmative: null,
    buttonLabelNegative: 'No'
  }
}

export const DIALOG_DONE = {
  component: DIALOG_CONFIRM,
  props: {
    title: i18n.t('dialogs.title.information'),
    text: i18n.t('dialogs.text.done'),
    buttonLabelAffirmative: 'Ok',
    buttonActionAffirmative: () => {
      store.dispatch('ui/closeDialog')
    },
    buttonLabelNegative: ''
  }
}

export const DIALOG_AVISO = {
  component: DIALOG_CONFIRM,
  props: {
    title: '',
    text: '',
    buttonLabelAffirmative: i18n.t('buttons.access'),
    buttonActionAffirmative: () => {
      store.dispatch('ui/closeDialog')
    },
    buttonLabelNegative: ''
  }
}

export const DIALOG_CONFIRM_CREATE = {
  component: DIALOG_CONFIRM,
  props: {
    title: i18n.t('dialogs.title.confirmation'),
    text: '',
    buttonLabelAffirmative: i18n.t('buttons.accept'),
    buttonLabelNegative: i18n.t('buttons.cancel'),
    buttonActionNegative: () => {
      store.dispatch('ui/closeDialog')
    }
  }
}
