import { DIALOG_ERROR } from '@/components/dialogs'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import GenericConfirmedDialog from '@/views/common/dialogs/GenericConfirmedDialog.vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import ExternalUserDetails from '@/views/consultas/documentos/details/ExternalUserDetails.vue'
import DocumentDetails from '@/views/consultas/documentos/details/DocumentDetails.vue'
import getTransaction from '@/services/transactions/getTransaction.management'

export const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const operations = [
  { name: 'Menor (<)', code: 'lt' },
  { name: 'Menor o Igual (≤)', code: 'lte' },
  { name: 'Mayor (>)', code: 'gt' },
  { name: 'Mayor o Igual (≥)', code: 'gte' },
  { name: 'Igual (=)', code: 'eq' },
  { name: 'Distinto (≠)', code: 'neq' },
  { name: 'Similar (~)', code: 'like' }
]

export const string_operations = [
  { name: 'Igual (=)', code: 'eq' },
  { name: 'Similar (~)', code: 'like' }
]

export const numeric_operations = [
  { name: 'Menor (<)', code: 'lt' },
  { name: 'Menor o Igual (≤)', code: 'lte' },
  { name: 'Mayor (>)', code: 'gt' },
  { name: 'Mayor o Igual (≥)', code: 'gte' },
  { name: 'Igual (=)', code: 'eq' },
  { name: 'Distinto (≠)', code: 'neq' }
]

export const date_operations = [
  { name: 'Menor (<)', code: 'lt' },
  { name: 'Menor o Igual (≤)', code: 'lte' },
  { name: 'Mayor (>)', code: 'gt' },
  { name: 'Mayor o Igual (≥)', code: 'gte' },
  { name: 'Igual (=)', code: 'eq' },
  { name: 'Distinto (≠)', code: 'neq' }
]

export const list_operations = [
  { name: 'Igual (=)', code: 'eq' }
]

export const FlowIdsForFlujo9View = [85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 101, 103, 125]
export const FlowIdsForContract61View = [95]
export const FlowIdsForRRHHView = [131, 139]
export const FlowIdsForRRHHSindicatoView = [131, 139]
export const FlowIdsForCNMView = [107, 110]

export const getTranscurrido = (date: string) => {
  let result = ''
  const dateModified = new Date(date)
  const dateNow = new Date()
  const seconds = Math.floor((dateNow.valueOf() - (dateModified.valueOf())) / 1000)
  let mins = Math.floor(seconds / 60)
  let horas = Math.floor(mins / 60)
  const dias = Math.floor(horas / 24)
  horas = horas - (dias * 24)
  mins = mins - (dias * 24 * 60) - (horas * 60)

  if (dias > 0) {
    if (dias === 1) {
      result += dias + ' dia '
    } else {
      result += dias + ' dias '
    }
  }
  if (horas > 0) {
    if (horas === 1) {
      result += horas + ' hora '
    } else {
      result += horas + ' horas '
    }
  }
  if (mins > 0) {
    if (mins === 1) {
      result += mins + ' minuto'
    } else {
      result += mins + ' minutos'
    }
  }
  return result
}

export const getDias = (date: string) => {
  const dateModified = new Date(date)
  const dateNow = new Date()
  const seconds = Math.floor((dateNow.valueOf() - (dateModified.valueOf())) / 1000)
  const mins = Math.floor(seconds / 60)
  const horas = Math.floor(mins / 60)
  const dias = Math.floor(horas / 24)

  return dias
}

export const getOperationNameByCode = (code: string) => {
  return operations.find((x: any) => x.code === code)?.name
}

export const isVistaAvanzada = (vista: string) => {
  return vista === 'Vista avanzada'
}

export const b64toBlob = (base64Data: string, contentType = '') => {
  contentType = contentType || ''
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)
    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

/**
 * Nos devuelve el objeto parámetro completo junto con sus campos valor y código
 * a partir de uno de estos dos campos
 * @param {Object | any} object
 * @param {string} type
 * @param {string} param
 * @return {Object}
 */
export const getParam = (object: any, type: string, param: string) => {
  if (!object || !(object.keys) || (object.keys.length > 0) || !param || !type) {
    return null
  } else {
    const found = (object).find((x: any) => x[type] === param)
    if (found) return found
    else return null
  }
}

export const getParamList = (object: any, type: string, paramArray: Array<string>, value: string) => {
  const response = []
  if (!object || !(object.keys) || (object.keys.length > 0) || !paramArray || !type || !value) {
    return null
  }
  paramArray.forEach(element => {
    response.push(getParam(object, type, element)[value])
  })
  return response
}

export const cleanObject = (object: any) => {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        cleanObject(v)
      }
      // eslint-disable-next-line no-mixed-operators
      if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined || v === '') {
        if (Array.isArray(object)) {
          object.splice((k as any), 1)
        } else if (!(v instanceof Date)) {
          delete object[k]
        }
      }
    })
  return object
}

export const cleanObjectNotNull = (object: any) => {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        cleanObjectNotNull(v)
      }
    })
  return object
}
// se pasa como string porque se usa en un combobox que no acepta números en el array
export const getYear = (): string[] => {
  const currentDate = moment()
  const currentYear = currentDate.year()
  const yearsStop = currentYear - 5
  const years: string[] = []

  for (let year = currentYear; year > yearsStop; year--) {
    years.push(year.toString())
  }
  return years
}

const getRamdom = (min, max) => {
  return Math.round(Math.random() * (max - min) + min)
}

export const sort = (property) => {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}

export const getRamdomDate = (startDate?, endDate?) => {
  startDate = startDate || moment('2001-01-01')
  endDate = endDate || moment.now()
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS))
    now.add(10, 'days')
  }
  return dates[getRamdom(0, dates.length - 1)]
}

export const dateFormatter = (value, formatoOrigen, formatoDestino) => {
  return value ? moment(value, formatoOrigen).format(formatoDestino) : null
}

export const booleanFormatter = (value) => {
  return value ? 'SI' : 'NO'
}

export const currencyFormatter = (value) => {
  const euroLocale = Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR'
  })
  return euroLocale.format(value)
}

export const myCurrencyFormatter = (value: any) => {
  const number = value
  const result = number !== null && number !== undefined ? number.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '€' : ''
  return result
}

export const formatNumber = (number: number) => {
  const result = number !== null && number !== undefined ? number.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : ''
  return result
}

export const formatNumberDecimales = (params: any) => {
  const number = params.value
  const result = params.value ? number.toLocaleString('es-ES', { minimumFractionDigits: 2 }) : null
  return result
}

export const deleteFormatNumber = (number: string) => {
  const result = number !== null && number !== undefined ? number.replace('.', '') : ''
  return result
}

export const onRowClicked = (event) => {
  const node = event.node
  node.setSelected(!node.isSelected())
  return node.isSelected() ? event.data : null
}

export const onRowSelected = (event, rowSelectData, rowSelectNode) => {
  if (event.node.selected) {
    rowSelectData = event.node.data
    rowSelectNode = event.node
  } else if (!event.node.selected && rowSelectData === event.node.data) {
    rowSelectData = ''
    rowSelectNode = ''
  }
  return { ...rowSelectData, ...rowSelectNode }
}

/**
 * Devuelve la cabecera del fichero a partir de la extensión
 * @param extension Extensión del fichero
 * @returns Cabecera del fichero en base 64
 */
const getCabecera = (extension) => {
  switch (extension) {
    case '.xls':
    case '.xlsx':
      return 'data:application/vnd.ms-excel;base64,'
    case '.pdf':
      return 'data:application/pdf;base64,'
    default:
      return 'data:application/json;base64,'
  }
}

/**
 * Elimina la cabecera del fichero
 * @param extension Extensión del fichero
 * @returns Fichero sin la cabecera
 */
export const removeCabecera = (base64String) => {
  const index = base64String.indexOf(',') + 1
  const cortar = base64String.slice(0, index)
  return base64String.replace(cortar, '')
}

/**
 * Descarga de ficheros en base64
 * @param stream Respuesta del servicio de exportacion
 * @param name Nombre que tendrá fichero descargado
 */
export const downloadFile = async (stream, name: string, extension?: string) => {
  if (extension) {
    name = name.concat(extension)
  } else {
    extension = name.substring(name.indexOf('.'))
  }
  const cabecera = getCabecera(extension)

  const blob = new Blob([cabecera.concat(stream)])
  const reader = new FileReader()
  reader.readAsBinaryString(blob)
  reader.onload = (e) => {
    const link = document.createElement('a')
    link.href = e.target.result.toString()
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

/**
 * Conversión de ficheros a base64 para su posterior subida
 * @param file Fichero seleccionado desde un input file
 * @returns Promesa con el fichero en base64
 */
export const fileToBase64 = (file): any => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

/**
 * Decodifica el detalle del error de una llamada a un microservicio
 * @param details Detalle de la respuesta del servicio
 * @returns Concatenación de los dos primeros mensajes de error del servicio
 */
const decodeDetailsError = (details: any): string => {
  if (typeof (details) === 'object') {
    let result = ''
    let msg = ''
    let contador = 0
    for (const [key, value] of Object.entries(details)) {
      result += '\n' + key + ': ' + value
      contador++
      if (contador === 2) break
    }
    if (Object.values(details).length - contador === 0) {
      msg = `${result} \n`
    } else {
      msg = `${result} \n (+${Object.values(details).length - contador} error...)`
    }
    return msg
  } else {
    return details
  }
}

export const goTo = (name, route, id?) => {
  const finalRoute = id ? `${route}?id=${id}` : route
  const tabName = { name, route: finalRoute }
  if (router.currentRoute.path !== finalRoute) {
    router.push({ path: finalRoute, replace: true })
    const currentComponent = router.options.routes.find(item =>
      item.path === route
    ).name
    store.dispatch('ui/addOpenedTab', currentComponent)
  }
  return tabName
}

export const showDialogError = (mensaje) => {
  store.dispatch('ui/openDialog', Object.assign({}, DIALOG_ERROR, {
    props: {
      ...DIALOG_ERROR.props,
      text: mensaje
    }
  }))
}

export const logoutGenericError = (mensaje) => {
  store.dispatch('ui/openDialog', {
    component: GenericErrorDialog,
    props: {
      text: mensaje,
      functionCb: () => {
        document.dispatchEvent(new Event('logoutapp'))
      }
    }
  })
}

export const openTransactionDetailCallback = (result: any, cb: Function) => {
  let text = 'Se ha rechazado el documento'
  if (result === false) text = 'Se ha firmado el documento'
  store.dispatch('ui/openDialog', {
    component: GenericConfirmedDialog,
    props: {
      text: text,
      functionCb: cb
    }
  })
}

/**
 * Se encarga de abrir el detalle de una transacción, se utiliza desde varios puntos de la aplicación
 */
export const getTransactionDetails = (selectedTransaction: any, cb: Function, isExternalUser: boolean, from: string) => {
  store.dispatch('ui/showMask', {
    text: ' Cargando...'
  })
  const params = {
    id: selectedTransaction.id,
    evidences: selectedTransaction.evidences,
    history: selectedTransaction.history,
    year: selectedTransaction.year
  }
  getTransaction(params)
    .then((response) => {
      if (response?.transaction !== null) {
        store.dispatch('transactionDetail/updateCurrentTransaction', response.transaction)
        store.dispatch('ui/openDialog', {
          component: (isExternalUser) ? ExternalUserDetails : DocumentDetails,
          props: {
            userFinishedDocumentCb: (result) => {
              openTransactionDetailCallback(result, cb)
            },
            from: from
          }
        })
      }
    })
    .catch((err) => {
      console.log(err)
      const transactionRef = store.getters['transactionRef/transactionRef']
      if (transactionRef) {
        store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'No se ha encontrado el documento o no tienes permisos para visualizarlo'
          }
        })
      }
    })
    .finally(() => {
      store.dispatch('ui/hideMask')
    })
}

export const getStartOfDay = (date: Date) => {
  return new Date(date.setHours(0, 0, 0, 0))
}
export const getEndOfDay = (date: Date) => {
  return new Date(date.setHours(23, 59, 59, 999))
}

export const fechaHoraPdf = (): string => {
  const ahora = new Date()

  const dia = ('0' + ahora.getDate()).slice(-2) // Día con dos dígitos
  const mes = ('0' + (ahora.getMonth() + 1)).slice(-2) // Mes con dos dígitos (getMonth() devuelve de 0 a 11)
  const anio = ahora.getFullYear() // Año en formato completo
  const horas = ('0' + ahora.getHours()).slice(-2) // Horas con dos dígitos
  const minutos = ('0' + ahora.getMinutes()).slice(-2) // Minutos con dos dígitos

  return `${dia}${mes}${anio}${horas}${minutos}`
}
