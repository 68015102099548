import Pageable from '@/services/pageable/Pageable'
import PaginationRequestDTO from '../dto/PaginationRequestDTO'
import PaginationResponseDTO from '../dto/PaginationResponseDTO'

export const PaginationResponseDTOToPageable = (origin: PaginationResponseDTO): Pageable => {
  const result: Pageable = {
    pageNo: origin.number + 1,
    totalItems: origin.totalElements,
    pageSize: origin.size,
    orderBy: origin.sort,
    direction: ''
  }
  return result
}

export const PageableToPaginationRequestDTO = (origin: Pageable, orderBy: string): PaginationRequestDTO => {
  const result: PaginationRequestDTO = {
    orderBy: orderBy || origin.orderBy,
    direction: origin.direction,
    currentPage: origin.pageNo - 1,
    totalItems: origin.totalItems,
    pageSize: origin.pageSize
  }
  return result
}
