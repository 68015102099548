import StateDTO from '@/api/common/StateDTO'

export default class StatesResponse {
  fields?: Array<StateDTO>;

  constructor (params: any) {
    if (params && params.length > 0) {
      this.fields = Array.from(params)
    } else {
      this.fields = null
    }
  }
}
