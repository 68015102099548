
import disabledMixin from '../mixins/disabled'
import inputValidationMixin from '../mixins/inputValidation'

export default {
  name: 'TextArea',
  mixins: [disabledMixin, inputValidationMixin],
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    requiredMark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    dirty: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: null
    },
    errorMessages: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      // isValid: false
      valueData: this.value,
      invalidData: this.invalid,
      errorMessagesData: this.errorMessages,
      validationsData: this.validations
    }
  },
  computed: {
    invalidFlag () {
      return this.invalidData && this.dirty
    },
    errorMessagesActive () {
      if (!this.errorMessagesData) {
        return []
      }

      return Object.keys(this.errorMessagesData)
        .filter(key =>
          Object.prototype.hasOwnProperty.call(this.validationsData, key) && !this.validationsData[key]
        )
        .map(key => this.errorMessagesData[key])
    },
    helpTextVisible () {
      return this.errorHelpText || this.helpText
    },
    helpTextValue () {
      if (!this.errorHelpText || !this.errorMessagesActive.length || !this.invalidFlag) {
        return this.helpText
      }

      return this.errorMessagesActive[0]
    },
    classes () {
      return {
        textarea__disabled: this.disabledData,
        'textarea__invalid custom-textarea': this.invalidFlag,
        'custom-textarea': !this.invalidFlag
      }
    }
  },
  watch: {
    value (newVal) {
      this.valueData = newVal
    },
    invalid (newVal) {
      this.invalidData = newVal
    }
  },
  methods: {
    changeValue (value) {
      if (this.validations !== null) this.validations.$touch()
      this.$emit('input', value)
    },
    onBlur () {
      this.$emit('blur')
    }
  }
}
