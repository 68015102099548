import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import UserOperationRequestDTO from '../dto/UserOperationRequestDTO'
import UserOperationResponseDTO from '../dto/UserOperationResponseDTO'
import ChangePasswordRequestDTO from '../dto/ChangePasswordRequestDTO'
import ChangePasswordResponseDTO from '../dto/ChangePasswordResponseDTO'
import UsersRepository from '../UsersRepository'
import env from '@/services/environment'
import store from '@/store'

export default class UsersRepositoryImpl implements UsersRepository {
  getDefaultHeaders (): any {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
  }

  getExternalUser (params: UserOperationRequestDTO): Promise<AxiosResponse<UserOperationResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/' + encodeURIComponent(params?.email)
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  changepwdExternalUser (params: ChangePasswordRequestDTO): Promise<AxiosResponse<ChangePasswordResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/' + encodeURIComponent(params?.email) + '/password'
    const headers = this.getDefaultHeaders()
    const body = {
      password: params?.password,
      newpassword: params?.newpassword
    }
    return xhr.patch(msPFDUrl.concat(endpoint), body, { headers })
  }

  resetpwExternalUser (params: UserOperationRequestDTO): Promise<AxiosResponse<UserOperationResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui-externalUsers/externalUsers/' + encodeURIComponent(params?.email) + '/resetpwd'
    const headers = this.getDefaultHeaders()
    const queryParams = { notify: true }
    return xhr.patch(msPFDUrl.concat(endpoint), queryParams, { headers })
  }

  unlockExternalUser (params: UserOperationRequestDTO): Promise<AxiosResponse<UserOperationResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/' + encodeURIComponent(params?.email) + '/unlock'
    const headers = this.getDefaultHeaders()
    return xhr.patch(msPFDUrl.concat(endpoint), null, { headers })
  }
}
