import API from '@/api'
import DepartmentsResponse from './models/DepartmentsResponse'

const executeAction = async (): Promise<DepartmentsResponse> => {
  try {
    const response = await API.DropdownsRepository.getDepartments()
    return new DepartmentsResponse(response.data)
  } catch (err) {
    console.error('[getDepartments] - Ha ocurrido un error al obtener getDepartments')
    throw err
  }
}

export default executeAction
