import getUserGDPR from '@/services/administration/getUserGDPR.service'
import GetUserGDPRRequest from './models/GetUserGDPRRequest'

const executeAction = async (request: GetUserGDPRRequest) => {
  try {
    const response = await getUserGDPR(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[getUserGDPR] - Ha ocurrido un error al llamar a getUserGDPR')
    throw err
  }
}

export default executeAction
