
import Vue from 'vue'
import Button from '@/components/Button.vue'
import { FDialog, FIcon } from 'fwkc4-vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import warning from '../../assets/images/warning.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import info from '../../assets/images/info.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import face from '../../assets/images/face.svg'

export default Vue.extend({
  name: 'AlertDialog',
  components: {
    Button,
    FIcon,
    FDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Cerrar'
    },
    buttonText2: {
      type: String,
      default: 'Continuar'
    },
    buttonText3: {
      type: String,
      default: 'Cancelar'
    },
    twoButtons: {
      type: Boolean,
      default: false
    },
    threeButtons: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value () {
      const dialog: any = this.$refs.dialog
      dialog.toogleOpen()
    }
  },
  mounted () {
    if (this.value) {
      const dialog: any = this.$refs.dialog
      dialog.toogleOpen()
    }
  },
  methods: {
    primary () {
      this.$emit('primary')
    },
    secondary () {
      this.$emit('secondary')
    },
    tertiary () {
      this.$emit('tertiary')
    },
    onClose () {
      this.$emit('closeDialog')
    }
  }
})
