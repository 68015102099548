import CurrentUserRepository from '@/api/auth/CurrentUserRepository'
import CurrentUserDTO, { Resource } from '@/api/auth/dto/CurrentUserDTO'

export default class UserContext {
  lastLogin: Date | null = null;
  _active = false;
  activeDate: Date | null = null;
  fullName = '';
  username = '';
  attributes: Record<string, any> = {}
  profiles: string[] = []
  resources: Resource[] = []

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // eslint-disable-next-line no-useless-constructor
  constructor (private currentUserApi: CurrentUserRepository) {}

  async getCurrentUser () {
    try {
      const response = await this.currentUserApi.getCurrentUser()
      this.saveInfo(response.data)
    } catch (err) {
      console.error('Ha ocurrido un error al realizar la llamada a currentUser', err)
    }
  }

  set active (active: boolean) {
    if (active) {
      this.activeDate = new Date()
    }
    this._active = active
  }

  get active () {
    return this._active
  }

  clear () {
    this.lastLogin = null
    this.active = false
    this.fullName = ''
    this.username = ''
  }

  saveInfo (data: CurrentUserDTO) {
    const {
      fullname, username, lastAccess, attributes,
      profiles, resources
    } = data

    this.fullName = fullname
    this.username = username
    this.lastLogin = new Date(lastAccess)
    this.attributes = attributes
    this.profiles = profiles
    this.resources = resources

    const event = new Event('saveUserContext')
    document.dispatchEvent(event)
  }

  haveSession () {
    return this.lastLogin && this.username
  }
}
