
import Vue from 'vue'
import BasicDialog from '@/components/dialogs/BasicDialog.vue'
import Documento from './Documento.vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import cancelTransaction from '@/services/transactions/cancelTransaction.management'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ExternalUserDetails',
  components: {
    BasicDialog,
    Documento
  },
  props: {
    userFinishedDocumentCb: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      change: false,
      asunto: null,
      tipoDocumento: null,
      nombreDocumento: null,
      metadatos: null,
      transaction: null
    }
  },
  computed: {
    ...mapGetters('user', [
      'hasPanicButton'
    ]),
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    showPanicButton () {
      return this.hasPanicButton && this.getCurrentTransaction?.estado?.estadoInterno === 'PENDIENTE'
    },
    getContractUrl () {
      return this.transaction?.url
    }
  },
  async mounted () {
    this.subscribeEvents()
    try {
      this.$store.dispatch('ui/showMask')
      this.setInitialData()
    } catch (err) {
      console.log(err)
    } finally {
      this.$store.dispatch('ui/hideMask')
    }
  },
  methods: {
    subscribeEvents () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const me = this
      document.addEventListener('EIS01ReceiveMessage', function (e: any) {
        me.onEIS01ReceiveMessage(e.detail.result)
      })
    },
    onEIS01ReceiveMessage (result: string) {
      if (result !== 'REJECTED') {
        this.onCloseDialog()
        this.userFinishedDocumentCb(result === 'REJECTEDMSG')
      }
    },
    setInitialData () {
      this.change = false
      this.transaction = this.getCurrentTransaction
    },
    async onPanicButton () {
      this.$store.dispatch('ui/showMask', {
        text: ' Cargando...'
      })
      let response = null
      try {
        const transaction = this.getCurrentTransaction
        const params = {
          user: this.getUsername,
          transaction_id: transaction.id
        }
        response = await cancelTransaction(params)
        if (response?.status === 'CANCELLED') {
          this.search()
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    onCloseDialog () {
      this.$emit('closed')
    }
  }
})
