import FlowDTO from '@/api/common/FlowDTO'

export default class FlowsResponse {
  fields?: Array<FlowDTO>;

  constructor (params: any) {
    if (params && params.length > 0) {
      this.fields = Array.from(params)
    } else {
      this.fields = null
    }
  }
}
