import { RootStore } from '@/store/rootStore'
import { StateControlAcceso } from './types'
import { Module } from 'vuex'
import i18n from '@/i18n'

const getStateControlAcceso = () => {
  return [
    {
      texto: i18n.t('menus.menu').toString(),
      permiso: [
        {
          rol: 'UPFI0001',
          permiso: 'full'
        }
      ],
      items: [
        {
          texto: i18n.t('menus.usuarios').toString(),
          url: '/Usuarios'
        }
      ]
    }
  ]
}

const getStateControlAccesoRightMenu = () => {
  return [
    {
      texto: i18n.t('menus.informes').toString(),
      url: '',
      permiso: [
        {
          rol: 'UPFI0001',
          permiso: 'full'
        }
      ]
    },
    {
      texto: '|'
    },
    {
      texto: '',
      icon: 'info-circle',
      action: 'abrirEditorPlantilla',
      permiso: [
        {
          rol: 'UPFI0001',
          permiso: 'full'
        }
      ]
    }
  ]
}

export const controlAccesoMenu: Module<StateControlAcceso, RootStore> = {
  namespaced: true,

  state: {
    menu: getStateControlAcceso(),
    rightMenu: getStateControlAccesoRightMenu()
  },

  mutations: {
  },

  actions: {

  },
  getters: {
    getMenu (state) {
      return state.menu
    },
    getRightMenu (state) {
      return state.rightMenu
    }
  }
}

export default controlAccesoMenu
