import TransactionStepDTO from '@/api/common/TransactionStepDTO'

export default class GetTransactionStepsResponse {
  steps?: Array<TransactionStepDTO>;

  constructor (params: any) {
    if (params && params.length > 0) {
      this.steps = Array.from(params)
    } else {
      this.steps = null
    }
  }
}
