const input = {
  props: {
    invalid: Boolean,
    dirty: Boolean,
    errorMessages: Object,
    validations: Object,
    errorHelpText: Boolean,
    helpText: String
  },
  computed: {
    invalidFlag () {
      return this.invalidData && this.dirty
    },
    errorMessagesActive () {
      if (!this.errorMessagesData) {
        return []
      }

      return Object.keys(this.errorMessagesData)
        .filter(key =>
          Object.prototype.hasOwnProperty.call(this.validationsData, key) && !this.validationsData[key]
          // this.validationsData.hasOwnProperty(key) && !this.validationsData[key]
        )
        .map(key => this.errorMessagesData[key])
    },
    helpTextVisible () {
      return this.errorHelpText || this.helpText
    },
    helpTextValue () {
      if (!this.errorHelpText || !this.errorMessagesActive.length || !this.invalidFlag) {
        return this.helpText
      }

      return this.errorMessagesActive[0]
    }
  },
  watch: {
    invalid (newVal) {
      this.invalidData = newVal
    }
  },
  data () {
    return {
      invalidData: this.invalid,
      errorMessagesData: this.errorMessages,
      validationsData: this.validations
    }
  },
  methods: {
    /**
     * @vuese multiple
     * Retorna el estado válido o inválido del componente
     * @return [boolean]
     */
    isValid () {
      return !this.invalid
    },
    getErrorMessagesActive () {
      return this.errorMessagesActive
    }
  }
}

export default input
