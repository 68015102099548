import Pageable from '@/services/pageable/Pageable'
import UsuarioDTO from '../dto/UsuarioDTO'
import Usuario from '@/services/administracion/models/Usuario'
import UsuarioRequestDTO from '../dto/UsuarioRequestDTO'
import { PageableToPaginationRequestDTO } from '@/api/pagination/mapping/MappingPagination'
import UsuarioRequest from '@/services/administracion/models/UsuarioRequest'

const fields = {
  usuario: 'usuario',
  nombre: 'nombre'
}

export const dtoToModel = (origin: UsuarioDTO): Usuario => {
  const result: Usuario = {
    usuario: origin.usuario,
    nombre: origin.nombre
  }
  return result
}

export const modelToDto = (origin: Usuario): UsuarioDTO => {
  const result: UsuarioDTO = {
    usuario: origin.usuario,
    nombre: origin.nombre
  }
  return result
}

export const modelRequestToDTO = (origin: UsuarioRequest): UsuarioRequestDTO => {
  const result: UsuarioRequestDTO = {
    q: origin.query,
    pagination: PageableToPaginationRequestDTO(origin.pagination, fields[origin.pagination.orderBy])
  }
  return result
}
