import getTransaction from '@/services/transactions/getTransaction.service'
import GetTransactionRequest from './models/GetTransactionRequest'

const executeAction = async (request: GetTransactionRequest) => {
  try {
    const response = await getTransaction(request)
    if (response) {
      return response
    }
    return null
  } catch (err) {
    console.error('[getTransaction] - Ha ocurrido un error al llamar a getTransaction')
    throw err
  }
}

export default executeAction
